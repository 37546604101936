import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

export const initialState = {
  broadcast: [],
  hasError: true,
  loading: false,
};
export const userSlice = createSlice({
  name: "broadcast",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getAll_IOI: (state, { payload }) => {
      state.broadcast = payload;
    },
    getUserFailure: (state) => {
      state.hasError = true;
    },
  },
});

export const { getLoading, getAll_IOI, getUserFailure } = userSlice.actions;

export const broadcastSelector = (state) => state.broadcast;
export default userSlice.reducer;

export const fetchAllBroadcast = (value) => async (dispatch) => {
  dispatch(getLoading);
  try {
    const { data } = await axios.get(
      keyUri.BACKEND_URI + `/admin/blog-by-category/${value}`
    );
    dispatch(getAll_IOI(data.blog));
  } catch (error) {
    // //console.log(error)
    dispatch(getUserFailure());
  }
};
