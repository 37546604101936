import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";
import { fetchIndividualProfile } from "../api/individualUser";

// Retrieve token from local storage
const token = localStorage.getItem("token") || null;

// Define the initial state
export const initialState = {
  loading: false,
  hasErrors: false,
  publish: [],
  currentPublish: {},
  individualtoken: token,
};

// Redux slice for topic management
export const publishSlice = createSlice({
  name: "publish",
  initialState,
  reducers: {
    getPublishLoading: (state) => {
      state.loading = true;
      state.hasErrors = false; // Reset error state on new request
    },
    getAllPublishSuccessful: (state, { payload }) => {
      state.loading = false;
      state.publish = payload?.all_publishes || [];
      state.hasErrors = false;
    },
    getCurrentPublishSuccessful: (state, { payload }) => {
      state.loading = false;
      state.currentPublish = payload?.publish || {};
      state.hasErrors = false;
    },
    getPublishFailed: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

// Export actions
export const {
  getPublishLoading,
  getAllPublishSuccessful,
  getCurrentPublishSuccessful,
  getPublishFailed,
} = publishSlice.actions;

// Selector to access publish state
export const publishSelector = (state) => state.publish;

// Export reducer
export default publishSlice.reducer;

// Thunks for API calls

// Fetch all publishes
export const fetchAllPublish = () => async (dispatch) => {
  dispatch(getPublishLoading());
  try {
    const { data } = await axios.get(`${keyUri.BACKEND_URI}/individual-users/fetch-publish`, config);
    dispatch(getAllPublishSuccessful(data));
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log("Fetched all publish:", data);
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Failed to fetch publish.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getPublishFailed());
  }
};

// Fetch a single publish by ID
export const fetchOnePublish = (id) => async (dispatch) => {
  dispatch(getPublishLoading());
  try {
    const { data } = await axios.get(`${keyUri.BACKEND_URI}/individual-users/get-a-publish/${id}`, config);
    dispatch(getCurrentPublishSuccessful(data));
    console.log("Fetched publish data:", data);
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Failed to fetch publish.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getPublishFailed());
  }
};

// Create a new publish
export const createPublish = (value) => async (dispatch) => {
  dispatch(getPublishLoading());
  try {
    const { data } = await axios.post(`${keyUri.BACKEND_URI}/individual-users/create-publish`, value, config);
    
    dispatch(fetchIndividualProfile(token));
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log("Created new publish:", data);
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Failed to create publish.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getPublishFailed());
  }
};

export const deletePublish = (id) => async (dispatch) => {
  dispatch(getPublishLoading());
  const tok = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

  try {
    const { data } = await axios.delete(`${keyUri.BACKEND_URI}/individual-users/delete-a-publish/${id}`, config);
    dispatch(fetchIndividualProfile(tok))
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } catch (error) {
    const errorMessage = error?.response?.data?.message || "Failed to delete topic.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getPublishFailed());
  }
};
