import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import { individualUserSelector } from "../../api/individualUser";
import { Link, NavLink } from "react-router-dom";
import Image from "../../images/DomesticSolutions-16.jpg";
import Image1 from "../../images/AIforAI-17.png";
import Image2 from "../../images/DreamTrue-18.jpg";
import { broadcastSelector, fetchAllBroadcast } from "../../api/broadcast";
import { useEffect } from "react";
import Image3 from "../../images/brodcast.jpeg";

export default function AI() {
  const { user } = useSelector(individualUserSelector);
  const [type, setType] = useState("AI");
  const { broadcast } = useSelector(broadcastSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllBroadcast("A&I for AI"));
  }, []);
  // Add other image formats if needed

  const isVideo = (posterImage) => posterImage?.includes(".mp4");

  const isImage = (posterImage) =>
    [".jpg", ".jpeg", ".png", ".webp"].some((ext) =>
      posterImage?.includes(ext)
    );

  // //console.log(isVideo, "isvideo");
  // //console.log(isImage, "isimage");

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-2 bg-gray-100 rounded-xl overflow-hidden">
                <div className="flex flex-wrap justify-between ">
                  <h3
                    class="text-lg font-semibold text-[#0F3D79] mb-4"
                    style={{ marginLeft: "15px", marginTop: "10px" }}
                  >
                    Projects
                  </h3>
                </div>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/bharc"
                >
                  <img className="h-12 w-[150px] object-cover" src={Image3} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/domesticSolutions"
                >
                  <img className="h-12" src={Image} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/AI"
                  style={{
                    backgroundColor: type == "AI" ? "#CEDDF6" : "#F3F4F6",
                  }}
                >
                  <img className="h-12" src={Image1} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center  hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/dreamTrue"
                >
                  <img className="h-12" src={Image2} />
                </Link>
              </div>
            </div>
            <div class="w-full lg:w-[78%] px-3">
              <div class="h-[70vh]  py-4 px-3 bg-gray-100 rounded-xl overflow-y-scroll">
                {broadcast?.map((item, i) => {
                  return (
                    <div key={i} class="flex flex-wrap">
                      <div class="w-full md:w-full ">
                        <div class="relative h-full">
                          <div class="flex flex-wrap ">
                            <div class="mb-6 w-full lg:w-full">
                              <div class="flex flex-wrap justify-evenly items-start">
                                {isVideo(item?.poster_image) ? (
                                  <video
                                    src={item?.poster_image}
                                    className="mt-5 w-full lg:w-[400px] h-[400px] rounded-xl object-cover"
                                    controls
                                    alt="Video"
                                  />
                                ) : isImage(item?.poster_image) ? (
                                  <img
                                    src={item?.poster_image}
                                    className="mt-5 w-full lg:w-[400px] h-[400px] rounded-xl object-cover"
                                    alt="Image"
                                  />
                                ) : (
                                  <span>Unsupported format</span>
                                )}

                                <div class="w-full lg:w-1/2">
                                  <div class="flex-1">
                                    <h3 class="mb-3 text-lg font-bold font-heading text-[#0F3D79]"></h3>
                                    <p className="mb-2">
                                      <span className="text-xl font-semibold  text-[#0F3D79]">
                                        {item?.title}
                                      </span>
                                    </p>

                                    <span className="text-sm font-normal text-gray-500">
                                      {item?.description}
                                    </span>
                                  </div>
                                  {/*     
                                  <div>
                                  <a target="_blank" href={item?.link}>
                                      <h4 class="mt-5 text-lg float-left font-semibold bg-gray-200 py-1 hover:bg-gray-300 rounded-lg px-4 text-[#0F3D79] cursor-pointer">
                                        Read More
                                      </h4>
                                      </a>                                
                                        </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
