import { configureStore } from "@reduxjs/toolkit";
import verificationemailReducer from "./verifyemail";
import individualuserReducer from "./individualUser";
import ioiReducer from "./ioiUser";
import jobsReducer from "./jobs";
import brodcastReducer from "./broadcast";
import topicReducer from "./topic";
import publishReducer from "./publish";
import exploreReducer from "./explore";

export default configureStore({
  reducer: {
    verificationemail: verificationemailReducer,
    individualuser: individualuserReducer,
    ioiuser: ioiReducer,
    jobs: jobsReducer,
    topics: topicReducer,
    broadcast: brodcastReducer,
    publish: publishReducer,
    explore: exploreReducer,
  },
});
