import React, { useEffect, useState } from "react";
import "./Topics.css";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import DvrIcon from "@mui/icons-material/Dvr";
import {
  fetchIndividualProfile,
  individualUserSelector,
} from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WorkIcon from "@mui/icons-material/Work";
import image2 from "../../images/logo.png";
import nodata from "../../images/no-data.png";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllTopics,
  topicSelector,
  updateTopicStatus,
} from "../../api/topic";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  },
}));

function MyTopics() {
  const { individualtoken, user } = useSelector(individualUserSelector);
  const { topics, topicStatus } = useSelector(topicSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  console.log(user, "user");

  console.log(individualtoken, "token");
  console.log(topics, "topics in my topics");

  useEffect(() => {
    dispatch(fetchAllTopics());
    const ws = new WebSocket(
      "ws://king-prawn-app-p955d.ondigitalocean.app/api"
    );

    ws.onmessage = (event) => {
      console.log("web socket connected In topics");
      const message = JSON.parse(event.data);
      console.log(message, "this is messafe");
      if (message.type === "TOPIC_UPDATE") {
        console.log(`Topic ID: ${message.id}, Status: ${message.status}`);
        dispatch(updateTopicStatus({ id: message.id, status: message.status }));
      }
    };

    return () => {
      ws.close();
    };
  }, [topicStatus, dispatch]);

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredTopics = topics?.filter((topic) => {
    const topicName = topic.name.toLowerCase();
    const postedByName = topic.topic_posted_by?.name.toLowerCase();
    const query = searchQuery.toLowerCase();

    return topicName.includes(query) || postedByName.includes(query);
  });

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/topics">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        All Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Mytopics">
                  <div
                    className="flex group py-3 px-2 sm:px-8 items-center hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("Mytopics")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  <div className="m-4">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      My Topics
                    </h2>
                  </div>

                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search topic name or posted by"
                          className="py-2 pl-10 pr-4 w-full lg:w-1/2 rounded-xl border border-gray-300"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      <button
                        className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 lg:mr-2 lg:ml-4"
                        type="button"
                      >
                        <FilterIcon /> Filter
                      </button>
                      <Link to="/CreateTopics" className="w-full sm:w-auto">
                        <button
                          className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full sm:w-auto"
                          type="button"
                          onClick={reloadButton}
                        >
                          <AddIcon /> Create Topic
                        </button>
                      </Link>
                    </div>
                  </div>

                  {filteredTopics?.length > 0 &&
                  filteredTopics.some(
                    (topic) =>
                      topic.topic_status === "accept" &&
                      topic.topic_posted_by._id === user._id
                  ) ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {filteredTopics.map((topic) =>
                        topic.topic_status === "accept" ? (
                          <NavLink
                            key={topic._id}
                            to={`/TopicView/${topic._id}`}
                          >
                            <div className="bg-white p-6 rounded-xl shadow-md h-[400px]">
                              <div className="flex items-center mb-4">
                                <img
                                  src={
                                    topic?.topic_posted_by?.profile_pic
                                      ? topic?.topic_posted_by?.profile_pic
                                          ?.profile_pic
                                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                  }
                                  alt="Profile"
                                  className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                                />
                                <div>
                                  <h3 className="text-[#0F3D79] text-md font-semibold">
                                    {topic?.topic_posted_by?.name}
                                  </h3>
                                  <p className="text-sm text-gray-500">
                                    {
                                      topic?.topic_posted_by?.profile_completion
                                        ?.designation
                                    }
                                  </p>
                                </div>
                              </div>
                              <h4 className="text-[#0F3D79] font-semibold mb-2">
                                {topic.name}
                              </h4>
                              <p className="text-sm text-gray-600 mb-4">
                                {topic.summary.substring(0, 100)}...{" "}
                                <NavLink
                                  to={`/TopicView/${topic._id}`}
                                  className="text-blue-600"
                                >
                                  Read more
                                </NavLink>
                              </p>
                              <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                                <PictureAsPdfIcon
                                  style={{ fontSize: 50 }}
                                  className="text-blue-600"
                                />
                              </div>
                            </div>
                          </NavLink>
                        ) : null
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <img
                        src={nodata}
                        alt="No Data"
                        height={200}
                        width={200}
                      />
                      <p className="mt-2">No Data Available</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyTopics;
