import React, { useEffect, useState } from "react";
import "./Explore.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExploreIcon from "@mui/icons-material/Explore";
import { individualUserSelector } from "../../api/individualUser";
import {
  exploreSelector,
  fetchAllExplore,
  getCurrentExploreSuccessfull,
} from "../../api/explore";
import { NavLink } from "react-router-dom";
import Image1 from "../../images/comingsoon.png";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import WorkIcon from "@mui/icons-material/Work";
import image2 from "../../images/logo.png";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import noData from "../../images/no-data.png";
import image3 from "../../images/bharcimg.png";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

function ExploreDisplays() {
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );
  const dispatch = useDispatch();
  const nav = useNavigate();
  const classes = useStyles();
  const { pathname } = useLocation();

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };

  const { explore, currentExplore } = useSelector(exploreSelector);
  const [upcoming, setUpcoming] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  console.log(explore, "explore");

  useEffect(() => {
    dispatch(fetchAllExplore());
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchAllExplore());
    const ws = new WebSocket(
      "ws://king-prawn-app-p955d.ondigitalocean.app/api"
      // "ws://localhost:5000/api"
    );
    ws.onmessage = (event) => {
      console.log("web socket connected");
      const message = JSON.parse(event.data);
      if (message.type === "EVENT_CREATED") {
        console.log("event created");
        dispatch(getCurrentExploreSuccessfull({ currentExplore }));
      }
    };
    return () => {
      ws.close();
    };
  }, [currentExplore, dispatch]);

  useEffect(() => {
    // Get the current date
    const currentDate = new Date();

    // Filter upcoming conferences based on the date and search query
    const filterUpcoming = explore.filter((conference) => {
      const conferenceDate = new Date(conference.date); // Assuming `date` field exists
      const conferenceName =
        conference?.explore_posted_by_ioi?.name.toLowerCase() || "";
      const query = searchQuery.toLowerCase();

      // Check if the conference date is in the future and if the query matches the event name
      return conferenceDate > currentDate && conferenceName.includes(query);
    });

    // Set the filtered data to the upcoming state
    setUpcoming(filterUpcoming);
  }, [explore, searchQuery]);

  console.log(upcoming, "upcoming conferences");
  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/explore">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("explore")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/OtherEvents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        OtherEvents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Explore
                      <span className="text-sm"> - Conferences</span>
                    </h2>
                  </div>

                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    {/* Left-aligned search bar */}
                    <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          value={searchQuery}
                          onChange={handleSearchChange}
                          type="text"
                          placeholder="Search company name"
                          className="py-2 pl-10 pr-4 w-full lg:w-1/2 rounded-xl border border-gray-300"
                        />
                      </div>
                    </div>
                    {/* Right-aligned filter and create buttons */}
                    <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      <button
                        className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 lg:mr-2 lg:ml-4"
                        type="button"
                      >
                        <FilterIcon /> Filter
                      </button>

                      <button
                        className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto"
                        type="button"
                      >
                        <CalendarTodayIcon /> Date
                      </button>
                    </div>
                  </div>

                  {/* Topic Cards */}
                  <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3">
                    Upcoming Conferences
                  </h2>
                  {upcoming.length > 0 ? (
                    <div className="m-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                      {upcoming.map((event, index) => (
                        <div
                          key={index}
                          className="bg-white p-6 rounded-xl shadow-md flex flex-col h-[360px] overflow-hidden"
                        >
                          <div className="flex items-center mb-4">
                            <img
                              src={
                                event?.explore_posted_by_ioi?.profile_completion
                                  ?.logo || ""
                              } // Replace this with your image source
                              alt="Profile"
                              className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-cover"
                            />
                            <div>
                              <h3 className="text-[#0F3D79] text-md font-semibold">
                                {event?.explore_posted_by_ioi?.name}
                              </h3>
                              <p className="text-sm text-gray-500">
                                {event?.explore_posted_by_ioi?.sub_category}
                              </p>
                            </div>
                          </div>
                          <h4 className="text-[#0F3D79] font-semibold mb-2">
                            {event?.event_name}
                          </h4>
                          <p className="text-gray-600 text-sm font-semibold mb-4">
                            {new Date(event?.date).toLocaleDateString("en-IN", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                          </p>

                          <div className="flex-grow flex justify-center items-center bg-gray-100 rounded-lg overflow-hidden">
                            <img
                              loading="lazy"
                              src={event?.document}
                              alt="Description of the image"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center m-5 h-[360px]">
                      <img
                        src={noData}
                        alt="No Data"
                        height={200}
                        width={200}
                      />
                      <p className="mt-2 text-gray-500">
                        No Upcoming Conferences
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ExploreDisplays;
