import React, { useEffect } from "react";

import { useNavigate } from "react-router";
import {
  fetchIndividualProfile,
  sendConnectReq,
  individualUserSelector,
  setType,
} from "../../api/individualUser";
import { useDispatch, useSelector } from "react-redux";

export default function Section4({ recommendeIOI }) {
  const nav = useNavigate();
  const dispatch = useDispatch();

  const { individualtoken, user, connected, request_received, type } =
    useSelector(individualUserSelector);

  // //console.log(recommendeIOI?.recommendedUsers, "this is recomenedded user");

  const reqHandel = (senderId, recieverId) => {
    // e.stopPropagation();
    dispatch(sendConnectReq({ senderId, recieverId }));
    dispatch(fetchIndividualProfile());
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      {recommendeIOI?.recommendedUsers?.length >= 1 && (
        <section class="py-8">
          <div class="container px-2 mx-auto">
            <div class="flex flex-wrap mb-4 items-center justify-between m-3">
              <div class="w-auto">
                <h4 class="text-lg font-semibold text-[#0F3D79]">
                  IOI Recommendations
                </h4>
                {/* <p class="text-xs font-medium text-gray-600 mb-4">Your subtitle text about team members goes here</p> */}
              </div>
              <div class="w-auto p-2">
                <a
                  class="inline-block py-2 px-4 text-lg leading-5 font-semibold text-[#0F3D79] hover:bg-gray-100 rounded-lg  cursor-pointer"
                  onClick={() => {
                    dispatch(setType("company"));
                    nav("/connection");
                  }}
                >
                  See More
                </a>
              </div>
            </div>
            <div class="flex flex-wrap rounded-xl">
              {recommendeIOI?.recommendedUsers?.map((item, i) => {
                if (i < 5 && item?.profile_activate_status) {
                  return (
                    <div
                      key={i}
                      class="w-full md:w-1/3 lg:w-1/5 p-2 cursor-pointer"
                      onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
                    >
                      <div class="relative p-2 bg-gray-100 shadow rounded-xl">
                        {/* <button class="absolute top-0 right-0 m-8 inline-block text-gray-400 hover:text-gray-500">
                    <svg
                      width="14"
                      height="14"
                      viewbox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.41 6.99994L12.71 2.70994C12.8983 2.52164 13.0041 2.26624 13.0041 1.99994C13.0041 1.73364 12.8983 1.47825 12.71 1.28994C12.5217 1.10164 12.2663 0.99585 12 0.99585C11.7337 0.99585 11.4783 1.10164 11.29 1.28994L7 5.58994L2.71 1.28994C2.5217 1.10164 2.2663 0.99585 2 0.99585C1.7337 0.99585 1.4783 1.10164 1.29 1.28994C1.1017 1.47825 0.995908 1.73364 0.995908 1.99994C0.995908 2.26624 1.1017 2.52164 1.29 2.70994L5.59 6.99994L1.29 11.2899C1.19627 11.3829 1.12188 11.4935 1.07111 11.6154C1.02034 11.7372 0.994202 11.8679 0.994202 11.9999C0.994202 12.132 1.02034 12.2627 1.07111 12.3845C1.12188 12.5064 1.19627 12.617 1.29 12.7099C1.38296 12.8037 1.49356 12.8781 1.61542 12.9288C1.73728 12.9796 1.86799 13.0057 2 13.0057C2.13201 13.0057 2.26272 12.9796 2.38458 12.9288C2.50644 12.8781 2.61704 12.8037 2.71 12.7099L7 8.40994L11.29 12.7099C11.383 12.8037 11.4936 12.8781 11.6154 12.9288C11.7373 12.9796 11.868 13.0057 12 13.0057C12.132 13.0057 12.2627 12.9796 12.3846 12.9288C12.5064 12.8781 12.617 12.8037 12.71 12.7099C12.8037 12.617 12.8781 12.5064 12.9289 12.3845C12.9797 12.2627 13.0058 12.132 13.0058 11.9999C13.0058 11.8679 12.9797 11.7372 12.9289 11.6154C12.8781 11.4935 12.8037 11.3829 12.71 11.2899L8.41 6.99994Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button> */}
                        <img
                          className="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                          src={
                            item?.profile_completion?.logo ||
                            "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                          }
                          alt=""
                        />

                        <h3 class="text-center font-semibold text-md text-[#0F3D79] whitespace-nowrap overflow-hidden text-ellipsis">
                          {item?.name?.charAt(0).toUpperCase() +
                            item?.name?.slice(1)}
                        </h3>
                        <h3 class="text-center text-sm text-gray-500   mt-1 mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
                          {item?.category?.charAt(0).toUpperCase() +
                            item?.category?.slice(1)}
                        </h3>
                        {/* <div class="text-center">
                <div class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer hover:hover:bg-[#0F3D79] hover:text-white" >
                  <span><SensorOccupiedIcon/></span>
                  <span class="relative px-3 text-lg"  onClick={(e)=>{e.stopPropagation();reqHandel(user?._id, item._id)}} >Connect</span>
                </div>
              </div> */}

                        {/* ------------------------------------request sent----------------------------------------------------- */}
                        {/* <div class="text-center">
          <a class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden" href="#">
          <span className=''><AddTaskRoundedIcon/></span>
            <span class="relative px-3 text-lg">Request Sent</span>
          </a>
        </div> */}
                        {/* -----------------------------------connected-------------------------------------------- */}
                        {/* <div class="text-center">
          <a class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden" href="#">
          <span className=''></span>
            <span class="relative px-3 text-lg">Connected</span>
          </a>
        </div> */}
                        {/* ------------------------------------------------------------------------------- */}
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
