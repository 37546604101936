import React, { useEffect } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import "./Jobs.css";
import Logo from "../../images/logo.png";
import WorkIcon from "@mui/icons-material/Work";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import ChecklistIcon from "@mui/icons-material/Checklist";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useDispatch, useSelector } from "react-redux";
import { fetchOneJob, jobsApply, jobsSelector } from "../../api/jobs";
import { makeStyles } from "@mui/styles";
import { Interweave } from "interweave";
import {
  fetchIndividualProfile,
  individualUserSelector,
  saveJob,
  unsaveJob,
} from "../../api/individualUser";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment/moment";
import { toast } from "react-toastify";
import  bharcImg  from "../../images/bharcimg.png"

// const jobIcon = [<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M0 0h20v20H0Z" fill="none"/><path d="M16.4 5.2h-3.2V3.6A1.6 1.6 0 0 0 11.6 2H8.4a1.6 1.6 0 0 0-1.6 1.6v1.6H3.6A1.6 1.6 0 0 0 2 6.7v8.7A1.6 1.6 0 0 0 3.6 17h12.8a1.6 1.6 0 0 0 1.6-1.6V6.7a1.6 1.6 0 0 0-1.6-1.5Zm-4.8 0H8.4V3.6h3.2Z" fill="#56a8f7"/></svg>]

function JobDetails({ id }) {
  const dispatch = useDispatch();
  const { currentjob, loading } = useSelector(jobsSelector);
  const { user, individualtoken } = useSelector(individualUserSelector);
  // //console.log(individualtoken, "indivsgfg");

  const useStyles = makeStyles((theme) => ({
    boxContainer: {
      marginBottom: "20px",
      // height: '100px',
      width: "100%",
      overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
      wordWrap: "break-word", // For compatibility with older browsers
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchOneJob(id));
    dispatch(fetchIndividualProfile(individualtoken));
  }, [id]);

  const tostPop = () => {
    toast.warning("You have already applied", {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  return (
    <div style={{ fontFamily: "Lato" }}>
      <Box
      // sx={{
      //   marginLeft: "20px",
      //   color: "grey",
      //   maxWidth: "700px",
      //   marginTop: "30px",
      //   padding: "5px",
      //   boxShadow: "0px 1px 2px 2px rgb(244,244,244)",
      //   padding: "20px",
      //   height: "83vh",
      //   overflowY: "scroll",
      // }}
      >
        <h3 class="mb-2 text-xl font-bold font-heading text-[#0F3D79]  ">
          {currentjob?.designation}
        </h3>
        <p>
          {currentjob?.job_posted_by?.name} &nbsp;{" "}
          {moment(currentjob?.createdAt).fromNow()}
        </p>
        <br></br>
        <p>
          <WorkIcon /> &nbsp; {currentjob?.job_type} &nbsp; &nbsp;{" "}
          {currentjob?.mode_of_work} &nbsp;
        </p>
        <p>
          <ApartmentIcon /> &nbsp; {currentjob?.company_size}. IT Services and
          IT Consulting
        </p>
        <p>
          <LightbulbIcon /> &nbsp;
          {currentjob?.applicants?.length == 1
            ? `${currentjob?.applicants?.length} applicant for this job`
            : `${currentjob?.applicants?.length} applicants for this job`}
        </p>
        {/* <p>
          <ChecklistIcon /> &nbsp; Skills: HTML5, Cascading Style Sheets (CSS),
          +8 more
        </p> */}

        <br></br>
        <Box>
          {currentjob?.applicants?.length ? (
            <>
              {currentjob?.applicants?.find(
                (item) => item?._id == user?._id
              ) ? (
                <Button
                  variant="contained"
                  color=""
                  style={{
                    marginRight: "40px",
                    backgroundColor: "green",
                    color: "white",
                  }}
                  onClick={tostPop}
                >
                  <CheckIcon /> &nbsp; Applied
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: "40px" }}
                  onClick={() =>
                    dispatch(jobsApply(currentjob?._id, { id: user?._id }))
                  }
                >
                  Apply
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: "40px" }}
                onClick={() =>
                  dispatch(jobsApply(currentjob?._id, { id: user?._id }))
                }
              >
                Apply
              </Button>
            </>
          )}
          {user?.jobs_saved?.find((item) => {
            // console.log(
            //   "Job ID:",
            //   item?._id,
            //   "Current Job ID:",
            //   currentjob?._id
            // );
            return item?._id === currentjob?._id;
          }) ? (
            <Button variant="contained" color="primary">
              Saved
            </Button>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                // console.log(
                //   "Dispatching saveJob:",
                //   user?._id,
                //   { id: currentjob?._id },
                //   individualtoken
                // );
                dispatch(
                  saveJob(user?._id, { id: currentjob?._id }, individualtoken)
                );
              }}
            >
              Save
            </Button>
          )}
        </Box>
        <h3 class="mt-5 text-xl font-bold font-heading text-[#0F3D79]  ">
          About job
        </h3>

        <div className="mt-5 text-gray-800">
          <h4 class="mt-5  font-bold font-heading text-[#0F3D79]  ">
            Job Description
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob.job_description}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
          <h4 class="mt-5 font-bold font-heading text-[#0F3D79]  ">
            Job Responsibility
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob.job_requirement}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
          <h4 class="mt-5 font-bold font-heading text-[#0F3D79]  ">
            Job Requirements
          </h4>
          <div className={classes.boxContainer}>
            <Interweave
              content={currentjob.job_responsibility}
              customElementAttributes={{
                ul: { className: "custom-ul" },
                li: { className: "custom-li" },
              }}
            />
          </div>
        </div>
      </Box>
    </div>
  );
}

export default JobDetails;
