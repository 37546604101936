import React from "react";
import { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import { Box, Tooltip } from "@mui/material";
import Modal from "@mui/material/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { ModalDialog } from "@mui/joy";
import Button from "@mui/joy/Button";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import storage from "../../shared/firebaseStorage";
import { useDispatch } from "react-redux";
import { updateIndividual } from "../../api/individualUser";
import { useEffect } from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { toast } from "react-toastify";
import { nanoid } from "@reduxjs/toolkit";

function ProfilePic({ user, individualtoken }) {
  const Text = `
  Image should be less than 100KB in size, with a 1:1 ratio of any dimensions, and only .jpg, .png, or .webp formats are allowed.
`;

  const dispatch = useDispatch();
  const [Logo, SetLogo] = useState("");
  const [uploadSuccessMsg, setLogoUploadSuccess] = useState("");
  const [logoPercent, setLogoPercent] = useState(0);
  const [open1, setOpen1] = React.useState(false);

  useEffect(() => {
    SetLogo(user?.profile_completion?.profile_pic);
    setLogoUploadSuccess("");
    setLogoPercent(100);
  }, [user]);

  const handelClose1 = () => {
    SetLogo(user?.profile_completion?.profile_pic);
    setOpen1(false);
  };

  const handleDeleteClickLogo = () => {
    // Example Firebase Storage URL
    const firebaseStorageURL = Logo;
    const urlParts = firebaseStorageURL.split("?");
    const pathParts = urlParts[0].split("/");
    const fileName = decodeURIComponent(pathParts[pathParts.length - 1]);
    const desertRef = ref(storage, `${fileName}`);
    deleteObject(desertRef)
      .then(() => {
        // File deleted successfully
        SetLogo("");
        setLogoUploadSuccess("");
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.log(error);
      });

    SetLogo("");
    setLogoUploadSuccess("");
  };

 
  const handleFileInputChange = (event) => {

    setLogoPercent(0);
    const newSelectedFile = event.target.files[0];
    const isJpgOrPng =
      newSelectedFile.type === "image/jpeg" ||
      newSelectedFile.type === "image/png" ||
      newSelectedFile.type === "image/webp";
  
    if (!isJpgOrPng) {
      toast.warning("You can only upload JPG/PNG/WEBP file!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return; // Exit the function if the file type is not valid
    }
  
    const isLt100KB = newSelectedFile.size / 1024 < 100;
    if (!isLt100KB) {
      toast.warning("Image size must be less than 100kb", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeButton: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return; // Exit the function if the file size is not valid
    }
  
    if (isJpgOrPng && isLt100KB) {
      const storageRef = ref(
        storage,
        `/Individual Profile Completion/${nanoid() + newSelectedFile.name}`
      );
   
  
      const uploadFile = uploadBytesResumable(storageRef, newSelectedFile);
   
  
      uploadFile.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // update progress
          setLogoPercent(percent);
          if (percent === 100) {
            setLogoUploadSuccess("Profile Uploaded Successfully.");
          }
        },
        (err) => {
          console.error(err);
        },
        () => {
          // download url
          getDownloadURL(uploadFile.snapshot.ref).then((url) => {
            SetLogo(url);
          });
        }
      );
    }
  };
  
  const submitProfilePic = (event) => {
    event.preventDefault();
    dispatch(
      updateIndividual(user?._id, { profile_pic: Logo }, individualtoken)
    );
    handelClose1();
  };


  return (
    <div style={{ fontFamily: "Lato" }}>
      <div class="mb-6">
        <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[300px]">
          <div style={{ marginLeft: "90%" }}>
            <button class="w-full text-md font-semibold text-gray-300 hover:text-gray-500 mt-3">
              <EditIcon onClick={() => setOpen1(true)} />
            </button>
          </div>
          <img
            class="relative w-32 h-32 mx-auto rounded-full object-cover object-right"
            src={
              user?.profile_completion?.profile_pic
                ? user?.profile_completion?.profile_pic
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
            }
            alt=""
          />
          <div class="mt-5 px-6 text-center">
            <p class="text-lg font-semibold text-[#0F3D79]">{user?.name}</p>
            <p class="text-md font-semibold text-gray-500">
              {user?.profile_completion?.category === "Student"
                ? "Student"
                : user?.profile_completion?.designation
                    ?.charAt(0)
                    .toUpperCase() +
                  user?.profile_completion?.designation?.slice(1)}
            </p>
            <p class="text-sm font-semibold text-gray-500 mb-4">
              {user?.unique_id}
            </p>
          </div>
        </div>
      </div>
      <React.Fragment>
        <Modal open={open1}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            sx={(theme) => ({
              [theme.breakpoints.only("xs")]: {
                top: "unset",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
                transform: "none",
                maxWidth: "unset",
              },
            })}
          >
            <div class="py-5">
              <div class=" flex w-full md:w-auto p-2 ">
                <h2 class="text-[#0F3D79] text-lg font-semibold">
                  Upload Profile image:
                </h2>
                <Tooltip title={Text}>
                  <p className="ml-2">
                    <InfoOutlinedIcon />
                  </p>
                </Tooltip>
              </div>

              <div className=" ml-8">
                <label>
                  <Button
                    style={{ marginTop: "3%" }}
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                    variant="outlined"
                    color="neutral"
                  >
                    {Logo ? (
                      <>
                        <img
                          src={Logo}
                          className="block mx-auto mb-5 w-28 h-28 rounded-full "
                        />
                        <button
                          type="button"
                          className=""
                          onClick={handleDeleteClickLogo}
                        >
                          <p className="text-gray-400 hover:text-gray-700 ">
                            <DeleteIcon />
                          </p>
                        </button>
                      </>
                    ) : (
                      <input
                        className="w-30"
                        type="file"
                        accept="image/*"
                        onChange={handleFileInputChange}
                      />
                    )}
                  </Button>
                </label>
                {/* {Logo &&<button
                  type="button"
                  className=""
                  onClick={handleDeleteClickLogo}
                > 
                  <p className="text-gray-400 hover:text-gray-700 ">
                    <DeleteIcon />
                  </p>
                </button>} */}
                <>
                  {logoPercent < 100 && Logo ? (
                    <span
                      className="ml-5 font-semibold"
                      style={{ color: logoPercent < 100 ? "red" : "green" }}
                    >
                      Upload {logoPercent}%
                    </span>
                  ) : (
                    <p className="font-normal" style={{ color: "green" }}>
                      {uploadSuccessMsg}
                    </p>
                  )}
                </>
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                marginLeft: "30px",
                gap: 1,
                // flexDirection: { xs: "column", sm: "row-reverse" },
              }}
            >
              <Button variant="outlined" color="neutral" onClick={handelClose1}>
                Cancel
              </Button>
              <Button
                variant="solid"
                color="contained"
                style={{
                  backgroundColor: "#0066A2",
                  color: "white",
                }}
                onClick={submitProfilePic}
              >
                Submit
              </Button>
            </Box>
          </ModalDialog>
        </Modal>
      </React.Fragment>
    </div>
  );
}

export default ProfilePic;
