import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptConnectionRequest,
  declineConnectionRequest,
  diconnectIndividual,
  getIndividualProfile,
  individualUserSelector,
  sendConnectReq,
} from "../../api/individualUser";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddTaskRoundedIcon from "@mui/icons-material/AddTaskRounded";
import { Button } from "@mui/joy";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { CiMenuKebab } from "react-icons/ci";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon } from "@mui/material";
import { AiOutlineDisconnect } from "react-icons/ai";
import Modal from "@mui/material/Modal";
import { ModalDialog } from "@mui/joy";
import { Box } from "@material-ui/core";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: '1000px',
  height: 300,
  bgcolor: "background.paper",
  border: "px solid #000",
  boxShadow: 2,
  p: 2,
};

function IndividualProfile() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { current_individual_user, individualtoken, user } = useSelector(
    individualUserSelector
  );
  const nav = useNavigate();
  const [openModel, setOpenModel] = useState(false);

  //menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handelModelClose = () => {
    setOpenModel(false);
  };

  useEffect(() => {
    dispatch(getIndividualProfile(id));
  }, [id]);

  // //console.log(current_individual_user, "hjghgghfdF");

  const openModelFunction = () => {
    if (
      user?.connections?.find(
        (connection) =>
          connection?.user == current_individual_user._id &&
          connection.status === "connected"
      )
    ) {
      setAnchorEl(null);
      setOpenModel(true);
    } else {
      setAnchorEl(null);
      toast.warning("User is not connected", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeButton: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const reqHandel = (senderId, recieverId) => {
    dispatch(sendConnectReq({ senderId, recieverId }, individualtoken));
  };

  const acptHandel = (acceptorId, recieverId) => {
    dispatch(
      acceptConnectionRequest({ acceptorId, recieverId }, individualtoken)
    );
  };

  const declineHandel = (userId, declinedId) => {
    dispatch(declineConnectionRequest({ userId, declinedId }, individualtoken));
  };

  const removeConnectFunt = () => {
    dispatch(
      diconnectIndividual(
        { userId: user?._id, connectedUserId: current_individual_user?._id },
        individualtoken
      )
    );
    setOpenModel(false);
  };

  return (
    <div>
      {
        current_individual_user?.profile_activate_status && (
          <section class="py-8" style={{ fontFamily: "Lato" }}>
            <div class="container mx-auto">
              {/* <Button variant='outlined' sx={{color:'gray', borderColor:'#F3F4F6',marginBottom:'20px'}}onClick={()=>nav(-1)}><KeyboardBackspaceIcon/></Button> */}
              <div class="flex flex-wrap">
                <div class="w-full lg:w-[28%] px-3">
                  <div class="mb-6">
                    <div class="relative bg-gray-100 shadow rounded-lg overflow-hidden h-[300px]">
                      {/* <div style={{ marginLeft: "90%" }}>
                        <button
                          color="inherit"
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                          class="w-full text-md font-semibold text-gray-500 mt-3"
                        >
                          <CiMenuKebab />
                        </button>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <MenuItem onClick={openModelFunction}>
                            <div className="px-5">
                              <ListItemIcon>
                                <AiOutlineDisconnect />
                              </ListItemIcon>
                              Disconnect
                            </div>
                          </MenuItem>
                        </Menu>
                      </div> */}
                      <img
                        class="relative w-32 h-32 mx-auto rounded-full object-cover object-right mt-5"
                        src={
                          current_individual_user?.profile_completion
                            ?.profile_pic
                            ? current_individual_user?.profile_completion
                                ?.profile_pic
                            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                        }
                        alt=""
                      />
                      <div class="px-6 text-center">
                        <p class="text-lg font-semibold text-[#0F3D79]">
                          {current_individual_user?.name}
                        </p>
                        <p class="text-md font-semibold text-gray-500">
                          {current_individual_user?.profile_completion
                            ?.category === ""
                            ? ""
                            : current_individual_user?.profile_completion
                                ?.category === "Student"
                            ? "Student"
                            : current_individual_user?.profile_completion?.designation
                                ?.charAt(0)
                                .toUpperCase() +
                              current_individual_user?.profile_completion?.designation?.slice(
                                1
                              )}
                        </p>

                        <p class="text-md font-semibold text-gray-500">
                          {" "}
                          {current_individual_user?.email}
                        </p>
                      </div>
                      <div class="px-6 text-center py-2">
                        {user?.connections?.find(
                          (connection) =>
                            connection?.user == current_individual_user?._id &&
                            connection.status === "connected"
                        ) ? (
                          <div class="text-center">
                            <a class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer">
                              <span className=""></span>
                              <span class="relative px-3 text-lg ">
                                Connected
                              </span>
                            </a>
                          </div>
                        ) : user?.connections?.find((connection) => {
                            return (
                              connection.status === "request sent" &&
                              connection.user == current_individual_user?._id
                            );
                          }) ? (
                          <div class="text-center">
                            <a class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer">
                              <span className="text-sm">
                                <AddTaskRoundedIcon />
                              </span>
                              <span class="relative px-3 text-lg">
                                Requested
                              </span>
                            </a>
                          </div>
                        ) : user?.connections?.find((connection) => {
                            return (
                              connection.status === "request received" &&
                              connection.user == current_individual_user?._id
                            );
                          }) ? (
                          <div className="flex gap-2 justify-center">
                            <div class="text-center ">
                              <a
                                class="relative group inline-block py-2 px-2 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  acptHandel(
                                    user?._id,
                                    current_individual_user?._id
                                  );
                                }}
                              >
                                <span class="relative px-3 text-md">
                                  Accept
                                </span>
                              </a>
                            </div>
                            <div class="text-center">
                              <a
                                class="relative group inline-block py-2 px-3 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  declineHandel(
                                    user?._id,
                                    current_individual_user?._id
                                  );
                                }}
                              >
                                <span class="relative px-3 text-md">
                                  Decline
                                </span>
                              </a>
                            </div>
                          </div>
                        ) : (
                          <div class="text-center mb-2">
                            {/* <a
                              class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer "
                              onClick={(e) => {
                                e.stopPropagation();
                                reqHandel(
                                  user?._id,
                                  current_individual_user?._id
                                );
                              }}
                            >
                              <span className="">
                                <SensorOccupiedIcon />
                              </span>
                              <span class="relative px-3 text-lg">Connect</span>
                            </a> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div class="p-6 bg-gray-100 rounded-xl h-[300px] mb-5">
                    <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                      <div class="w-auto px-4">
                        <h3 class="text-lg font-semibold text-[#0F3D79]">
                          Interested Domains
                        </h3>
                      </div>
                      <div class="w-full">
                        <div class="h-full py-3 px-5 rounded-xl">
                          <div class="mb-8 -mx-4">
                            <div class="-mb-3 text-start white-space-no-wrap">
                              {current_individual_user?.profile_completion?.skills?.map(
                                (item, i) => {
                                  return (
                                    i < 6 && (
                                      <p class="px-2 pl-5 py-1 my-1 mb-1  text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                                        {item}
                                      </p>
                                    )
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-full lg:w-[42%] px-3 mb-5">
                  <div class="container mx-auto">
                    <div className="bg-gray-100 shadow rounded-lg overflow-hidden lg:h-[300px]">
                      <div className="px-3 py-3">
                        <div className="lg:flex p-2">
                          <div className="w-full lg:w-100 mr-5">
                            <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                              <div className="w-auto px-4 mt-1">
                                <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
                                  Education Details
                                </h3>
                              </div>
                            </div>
                            <div className="mt-5 text-md font-medium text-gray-500">
                              <p
                                className="font-semibold"
                                style={{ color: "#000" }}
                              >
                                {
                                  current_individual_user?.profile_completion
                                    ?.education_details_1?.institute_name1
                                }
                              </p>
                              <p>
                                {
                                  current_individual_user?.profile_completion
                                    ?.education_details_1?.education_level1
                                }
                              </p>
                              <p>
                                {
                                  current_individual_user?.profile_completion
                                    ?.education_details_1?.course_name1
                                }
                              </p>
                              <p>
                                {current_individual_user?.profile_completion
                                  ?.education_details_1?.start_year1 &&
                                current_individual_user?.profile_completion
                                  ?.education_details_1?.end_year1
                                  ? `${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_1.start_year1
                                    ).format("YYYY")}-${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_1.end_year1
                                    ).format("YYYY")}`
                                  : ""}
                              </p>
                            </div>
                            <div className="mt-5 text-md font-medium text-gray-500">
                              <p
                                className="font-semibold"
                                style={{ color: "#000" }}
                              >
                                {
                                  current_individual_user?.profile_completion
                                    ?.education_details_2?.institute_name2
                                }
                              </p>
                              <p>
                                {
                                  current_individual_user?.profile_completion
                                    ?.education_details_2?.education_level2
                                }
                              </p>
                              <p>
                                {
                                  current_individual_user?.profile_completion
                                    ?.education_details_2?.course_name2
                                }
                              </p>
                              <p>
                                {current_individual_user?.profile_completion
                                  ?.education_details_2?.start_year2 &&
                                current_individual_user?.profile_completion
                                  ?.education_details_2?.end_year2
                                  ? `${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_2.start_year2
                                    ).format("YYYY")}-${dayjs(
                                      current_individual_user.profile_completion
                                        .education_details_2.end_year2
                                    ).format("YYYY")}`
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5 bg-gray-100 shadow rounded-lg overflow-hidden lg:h-[300px]">
                      <div
                        className="px-3 py-3"
                        style={{
                          display:
                            current_individual_user?.profile_completion
                              ?.category === "Student"
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="lg:flex px-3 py-3">
                          <div className="w-100 mr-5">
                            <div>
                              <div className="w-auto mt-1">
                                <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
                                  Career Objectives
                                </h3>
                              </div>
                              <div className="text-md font-medium text-gray-500">
                                <p>
                                  {
                                    current_individual_user?.profile_completion
                                      ?.career_objectives
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="px-3 py-3"
                        style={{
                          display:
                            current_individual_user?.profile_completion
                              ?.category !== "Student"
                              ? "block"
                              : "none",
                        }}
                      >
                        {/* <div className="lg:flex  p-2">
                        <div className="w-100 mr-5">
                          <div className="flex flex-wrap -mx-4 -mb-4 justify-between items-center ml-0">
                            <div className="w-auto mt-1">
                              <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
                                Career Details
                              </h3>
                            </div>
                          </div>
                          <div className="mt-5 text-md font-medium text-gray-500">
                            <p>
                 
                              
                            </p>
                            <p>
                            
                            
                            </p>
                            <p>
                             
                             
                            </p>
                            <p>
                          
                              {current_individual_user?.profile_completion?.experience_details_1?.expStart_year1 &&
                              current_individual_user?.profile_completion?.experience_details_1?.expEnd_year1
                                ? `${dayjs(current_individual_user.profile_completion.experience_details_1.expStart_year1).format("YYYY")}-${dayjs(current_individual_user.profile_completion.experience_details_1.expEnd_year1).format("YYYY")}`
                                : ""}
                            </p>
                          
                          </div>
                        </div>
                      </div> */}
                        <div>
                          <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                            <div class="w-auto">
                              <h3
                                class="text-lg font-semibold text-[#0F3D79] mb-3"
                                style={{ marginLeft: "25px" }}
                              >
                                Career Details
                              </h3>
                            </div>
                            {/* {user?.profile_completion?.experience_details_1
                              ?.company_name1 == "" ||
                            user?.profile_completion?.experience_details_2
                              ?.company_name2 == "" ? (
                              <button class="w-auto px-2 mb-3 text-gray-500">
                                <AddIcon onClick={() => setOpen6(true)} />
                              </button>
                            ) : (
                              <button class="w-auto px-2 mb-3 text-gray-300 hover:text-gray-500">
                                <EditIcon onClick={() => setOpen6(true)} />
                              </button>
                            )} */}
                            {/* <button class="w-auto px-2 mb-10 text-gray-500">
                            <AddIcon />
                          </button>
                          <button class="w-auto px-2 mb-10 text-gray-300 hover:text-gray-500">
                            <EditIcon />
                          </button> */}
                          </div>
                          <div className="mt-5 ml-3 text-md font-medium text-gray-500">
                            <p class="font-semibold" style={{ color: "#000" }}>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_1?.company_name1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_1?.designation1
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_1?.company_location1
                              }
                            </p>
                          </div>
                          <div className="mt-5 ml-3 text-md font-medium text-gray-500">
                            <p class="font-semibold" style={{ color: "#000" }}>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_2?.company_name2
                              }
                            </p>

                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_2?.designation2
                              }
                            </p>
                            <p>
                              {
                                current_individual_user?.profile_completion
                                  ?.experience_details_2?.company_location2
                              }
                            </p>
                            {/* <p>
                              {
                                user?.profile_completion?.experience_details_2
                                  ?.company_location2
                              }
                            </p> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-full lg:w-[30%] px-3">
                  <div class=" p-6 bg-gray-100 rounded-xl lg:h-[300px] overflow-y-auto ">
                    <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                      <div class="w-auto px-4">
                        <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                          Achievements
                        </h3>
                      </div>
                    </div>

                    <div className="mt-2">
                      <p class=" text-md font-semibold text-gray-800">
                        {
                          current_individual_user?.profile_completion
                            ?.achivement_details_1?.achivement1
                        }
                      </p>
                    </div>
                    <div className=" flex mt-2">
                      {current_individual_user?.profile_completion
                        ?.achivement_details_1?.achived_year1 ? (
                        <h4 class="text-lg font-semibold text-[#0F3D79]">
                          {" "}
                          Year :
                        </h4>
                      ) : null}

                      <p class="ml-2 text-lg font-semibold text-gray-800">
                        {current_individual_user?.profile_completion
                          ?.achivement_details_1?.achived_year1
                          ? dayjs(
                              current_individual_user?.profile_completion
                                ?.achivement_details_1?.achived_year1
                            ).format("YYYY")
                          : ""}
                      </p>
                    </div>

                    <div className="mt-2">
                      <p class=" text-md font-semibold text-gray-800">
                        {
                          current_individual_user?.profile_completion
                            ?.achivement_details_2?.achivement2
                        }
                      </p>
                    </div>
                    <div className=" flex mt-2">
                      {current_individual_user?.profile_completion
                        ?.achivement_details_2?.achived_year2 ? (
                        <h4 class="text-lg font-semibold text-[#0F3D79]">
                          {" "}
                          Year :
                        </h4>
                      ) : null}

                      <p class="ml-2 text-lg font-semibold text-gray-800">
                        {current_individual_user?.profile_completion
                          ?.achivement_details_2?.achived_year2
                          ? dayjs(
                              current_individual_user?.profile_completion
                                ?.achivement_details_2?.achived_year2
                            ).format("YYYY")
                          : ""}
                      </p>
                    </div>
                  </div>

                  <div class=" mt-5 p-6 bg-gray-100 rounded-xl lg:h-[300px]">
                    <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                      <div class="w-auto px-4">
                        <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                          Awards
                        </h3>
                      </div>
                    </div>

                    <div className="mt-5">
                      <p class=" text-md font-semibold text-gray-800">
                        {
                          current_individual_user?.profile_completion
                            ?.award_details_1?.award1
                        }
                      </p>
                    </div>
                    <div className=" flex mt-2">
                      {current_individual_user?.profile_completion
                        ?.award_details_1?.awarded_year1 ? (
                        <h4 class="text-lg font-semibold text-[#0F3D79]">
                          {" "}
                          Year :
                        </h4>
                      ) : null}

                      <p class="ml-2 text-lg font-semibold text-gray-800">
                        {current_individual_user?.profile_completion
                          ?.award_details_1?.awarded_year1
                          ? dayjs(
                              current_individual_user?.profile_completion
                                ?.award_details_1?.awarded_year1
                            ).format("YYYY")
                          : ""}
                      </p>
                    </div>

                    <div className="mt-5">
                      <p class=" text-md font-semibold text-gray-800">
                        {
                          current_individual_user?.profile_completion
                            ?.award_details_2?.award2
                        }
                      </p>
                    </div>
                    <div className=" flex mt-2">
                      {current_individual_user?.profile_completion
                        ?.award_details_2?.awarded_year2 ? (
                        <h4 class="text-lg font-semibold text-[#0F3D79]">
                          {" "}
                          Year :
                        </h4>
                      ) : null}

                      <p class="ml-2 text-lg font-semibold text-gray-800">
                        {current_individual_user?.profile_completion
                          ?.award_details_2?.awarded_year2
                          ? dayjs(
                              current_individual_user?.profile_completion
                                ?.award_details_2?.awarded_year2
                            ).format("YYYY")
                          : ""}
                      </p>
                    </div>
                  </div>

                  <>
                    <Modal open={openModel} onClose={handelModelClose}>
                      <Box style={style}>
                        <ModalDialog
                          aria-labelledby="nested-modal-title"
                          aria-describedby="nested-modal-description"
                          sx={(theme) => ({
                            [theme.breakpoints.only("xs")]: {
                              top: "unset",
                              bottom: 0,
                              left: 0,
                              right: 0,
                              borderRadius: 0,
                              transform: "none",
                              maxWidth: "unset",
                            },
                          })}
                        >
                          <div className="text-center">
                            <h1>Are you sure, you want to disconnect!!</h1>
                          </div>

                          <div className="flex justify-center  mt-4">
                            <Button
                              variant="outlined"
                              color="neutral"
                              onClick={handelModelClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="solid"
                              color="contained"
                              style={{
                                backgroundColor: "#FF7E5D",
                                color: "white",
                                //   width: "25%",
                                marginLeft: "10px",
                              }}
                              onClick={removeConnectFunt}
                            >
                              Disconnect
                            </Button>
                          </div>
                        </ModalDialog>
                      </Box>
                    </Modal>
                  </>
                </div>
              </div>
            </div>
          </section>
        )
        // :
        //  <>
        //  <p className='text-center p-2 text-2xl text-red-600'>This profile has been deactivated</p>
        // </>
      }
    </div>
  );
}

export default IndividualProfile;
