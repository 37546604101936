import React, { useEffect, useState } from "react";
import "./Jobs.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchJobsByFollowing,
  fetchJobsBySkills,
  fetchAlljobs,
  jobsSelector,
} from "../../api/jobs";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WorkIcon from "@mui/icons-material/Work";
import {
  individualUserSelector,
  getAllIndividual,
} from "../../api/individualUser";
import { ioiuserSelector, recommend_IOI } from "../../api/ioiUser";
import { NavLink } from "react-router-dom";
import { TextField, IconButton } from "@material-ui/core";
import { Autocomplete } from "@mui/joy";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { job_category, subCategory } from "../../shared/constants";
import { useDebounce } from "use-debounce";
import { keyUri } from "../../key";
import axios from "axios";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Maincategory,
  Subcategoryjob,
  Subcategory2job,
  Subcategory3job,
  Subcategory4job,
} from "../../shared/constants";
import bharcImg from "../../images/bharcimg.png"

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

function JobDisplays() {
  const {
    individualtoken,
    user,
    connected,
    request_received,
    all_individual_user,
  } = useSelector(individualUserSelector);

  const dispatch = useDispatch();
  const { jobs, followedJobs } = useSelector(jobsSelector);
  const { all_ioi_user, ioiUser } = useSelector(ioiuserSelector);
  console.log(all_ioi_user, "current ioi user");
  console.log(jobs, "jobs");
  console.log(all_individual_user, "all");

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [debouncedText] = useDebounce(search, 1000);
  const [jobCategory, setJobCategory] = useState("");

  const [subcategory, setSubCategory] = useState("");

  const sub_categories = [
    ...Subcategoryjob,
    ...Subcategory2job,
    ...Subcategory3job,
    ...Subcategory4job,
  ];

  const { pathname } = useLocation();

  useEffect(() => {
    dispatch(fetchJobsBySkills(user?._id));
    dispatch(fetchJobsByFollowing(user?._id));
    dispatch(fetchAlljobs());
  }, [user]);

  useEffect(() => {
    if (debouncedText !== "" && jobCategory !== "") {
      setData([]);
      setJobCategory("");
      setSubCategory("");
    }
  }, [debouncedText]);

  const handleFormCategoryChange = (newValue) => {
    if (newValue && newValue.value !== undefined) {
      setJobCategory(newValue.value);
    }
  };

  const handleFormSubCategoryChange = (newValue) => {
    if (newValue && newValue.value !== undefined) {
      setSubCategory(newValue.value);
    }
  };

  useEffect(() => {
    let filteredData = jobs;

    if (jobCategory) {
      filteredData = filteredData.filter(
        (item) => item.job_category === jobCategory
      );
    }

    if (subcategory) {
      filteredData = filteredData.filter(
        (item) => item?.job_posted_by?.sub_category === subcategory
      );
    }

    setData(filteredData);
  }, [jobCategory, subcategory, jobs]);

  console.log(all_ioi_user, "current ioi user");
  console.log(jobs, "jobs");

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/jobs">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("jobs")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Find Jobs
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/jobapplied">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Applied
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/jobsave">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Saved
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/JobShortlisted">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Job Shortlisted
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div class="w-full lg:w-[78%] px-3">
              <div class="h-full  py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div class="md:max-w-6xl mx-auto">
                  {jobs?.length ? (
                    <div className="flex items-center justify-between flex-wrap">
                      <h3 className="py-4 px-5 mb-3 text-xl font-bold font-heading text-[#0F3D79]">
                        All Jobs
                      </h3>
                      <div className="w-full sm:w-auto flex flex-col sm:flex-row justify-end mb-6 px-5 lg:justify-end lg:mb-0">
                        <FormControl
                          className="w-full sm:w-[250px] lg:w-[250px] mb-4 sm:mb-0 sm:mr-4"
                          style={{ marginTop: "10px" }}
                        >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            placeholder="Select Category"
                            disableClearable
                            // options={sub_categories}
                            name="job_category"
                            options={job_category}
                            onChange={(event, newValue) => {
                              handleFormCategoryChange(newValue);
                            }}
                            value={jobCategory.job_category}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Category" />
                            )}
                          />
                        </FormControl>
                        <FormControl
                          className="w-full sm:w-[250px] lg:w-[250px]"
                          style={{ marginTop: "10px" }}
                        >
                          <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            placeholder="Select Sub Category"
                            disableClearable
                            // options={sub_categories}
                            name="sub_category"
                            options={sub_categories}
                            onChange={(event, newValue) => {
                              handleFormSubCategoryChange(newValue);
                            }}
                            value={subCategory?.job_posted_by?.sub_category}
                            renderInput={(params) => (
                              <TextField {...params} label="Select Sub Category" />
                            )}
                          />
                        </FormControl>
                      </div>
                    </div>) : (
                    <></>
                  )}
                  <div>
                    <div class="flex flex-wrap m-4 ">
                      {data?.map((item, i) => {
                        return (
                          <>
                            <div class="w-full md:w-[50%] p-2">
                              <div class="relative h-full bg-white border hover:border-gray-300 rounded-xl flex flex-col p-2">
                                {/* Upper Section */}
                                <div class="h-[full] flex">
                                  {/* Upper Left Section */}
                                  <div class="w-[25%] lg:w-[20%] p-1">
                                    <img  src={
                    item?.job_posted_by?.profile_completion?.logo
                        ? item?.job_posted_by?.profile_completion?.logo
                        : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                    } alt="Company" class="w-full h-auto lg:h-full object-fit border border-gray-300 rounded-2xl" />
                                  </div>

                                  {/* Upper Right Section */}
                                  <div class="w-[75%] lg:w-[80%] p-2 flex flex-col justify-center">
                                    <h3 class="text-lg font-bold mb-2 text-[#0F3D79]">
                                      {item?.job_posted_by?.name}
                                    </h3>
                                    <p className="text-gray-500">
                                      {item?.job_posted_by?.sub_category}
                                    </p>
                                  </div>
                                </div>

                                {/* Bottom Section */}
                                <div class="h-[full] px-4  flex">
                                  {/* Left Section */}
                                  <div class="w-[80%] pr-2 flex flex-col justify-between">
                                    <div class="flex-1">
                                      <h4 class="mb-2 text-lg font-bold font-heading text-[#0F3D79]">
                                        {item?.designation}
                                      </h4>
                                      <p className="mb-2">
                                        <span className="text-gray-500">
                                          <LocationOnIcon />
                                          <span className="text-sm ml-2">
                                            {item?.job_type}, {item?.mode_of_work}
                                          </span>
                                        </span>
                                      </p>
                                      <span className="text-gray-500 py-2">
                                        <WorkIcon />
                                        <span className="ml-2">
                                          {item?.job_category}
                                        </span>
                                      </span>
                                    </div>
                                  </div>

                                  {/* Right Section */}
                                  <div class="w-[20%] flex items-center justify-end">
                                    <NavLink to={`/joblist/${item?._id}`}>
                                      <h4 class="text-sm font-semibold bg-[#0F3D79] hover:bg-[#0C2C60] text-white rounded-lg px-4 py-2 mt-10 cursor-pointer">
                                        View
                                      </h4>
                                    </NavLink>
                                  </div>
                                </div>
                              </div>
                            </div>
















                          </>
                        );
                      })}
                    </div>
                  </div>
                  {/* {followedJobs?.length ? (
                    <h3 class="py-4 text-xl font-bold font-heading text-[#0F3D79]  ">
                      Based on Following
                    </h3>
                  ) : (
                    <></>
                  )} */}
                  <div
                    style={{
                      overflowY: followedJobs.length > 3 ? "scroll" : "hidden",
                      height: "50vh",
                    }}
                  >
                    {/* <div class="flex flex-wrap m-4">
                      {followedJobs?.map((item, i) => {
                        return (
                          <div key={i} class="w-full md:w-1/4 p-2">
                            <div class="relative p-4 h-full bg-white border hover:border-gray-300 rounded-xl">
                              <div class="relative z-10 flex flex-col justify-between h-full">
                                <div class=" flex-1">
                                  <h3 class="mb-3 text-lg font-bold font-heading text-[#0F3D79]  ">
                                    {item.designation}
                                  </h3>

                                  <p className="mb-2">
                                    <span className="text-gray-500">
                                      <LocationOnIcon />
                                      <span className="text-sm ml-2">
                                        {item?.job_type} , {item?.mode_of_work}
                                      </span>
                                    </span>
                                  </p>

                                  <span className="text-gray-500">
                                    <WorkIcon />
                                    <span className=" ml-2">
                                      {item?.job_category}
                                    </span>
                                  </span>
                                </div>

                                <div>
                                  <NavLink to={`/joblist/${item?._id}`}>
                                    <h4 class="text-sm float-right font-semibold bg-gray-50 py-1 hover:bg-gray-200 rounded-lg px-4 text-[#0F3D79] cursor-pointer">
                                      View
                                    </h4>
                                  </NavLink>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JobDisplays;
