import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

const token = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;

export const initialState = {
  all_ioi_user: [],
  follow_sug: [],
  current_ioi_user: {},
  recommend_IOI: [],
  individualtoken: token,
  hasError: true,
  loading: false,
  ioiUser: [],
};

export const userSlice = createSlice({
  name: "ioiuser",
  initialState,
  reducers: {
    getLoading: (state) => {
      state.loading = true;
    },
    getAll_IOI: (state, { payload }) => {
      state.all_ioi_user = payload;
    },
    getFollowSug: (state, { payload }) => {
      state.loading = false;
      state.follow_sug = payload;
    },
    getOne_IOI: (state, { payload }) => {
      state.current_ioi_user = payload;
    },
    getUserFailure: (state) => {
      state.hasError = true;
    },
    getRecommendationIOI: (state, { payload }) => {
      state.recommend_IOI = payload;
    },
    getLocalStorageTokenIOI: (state, { payload }) => {
      state.individualtoken = payload;
      state.isAuthenticate = true;
    },
  },
});

export const {
  getLoading,
  getAll_IOI,
  getFollowSug,
  getOne_IOI,
  getUserFailure,
  getRecommendationIOI,
} = userSlice.actions;

export const ioiuserSelector = (state) => state.ioiuser;

export default userSlice.reducer;

export const fetchAllIOIProfile = () => async (dispatch) => {
  dispatch(getLoading);
  try {
    const { data } = await axios(keyUri.BACKEND_URI + "/users/all-ioi");
    dispatch(getAll_IOI(data.ioiUsers));
  } catch (error) {
    // //console.log(error);
    dispatch(getUserFailure());
  }
};

export const fetchOneIoiProfile = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/users/get-one-ioi/${id}`,
      config
    );
    dispatch(getOne_IOI(data?.ioiUser));
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const fetchFollowSugg = (id) => async (dispatch) => {
  dispatch(getLoading());
  try {
    const { data } = await axios(
      keyUri.BACKEND_URI + `/users/lets-start-to-follow-ioi/${id}`
    );
    dispatch(getFollowSug(data.letsFollowUsers));
  } catch ({ response }) {
    dispatch(getUserFailure(response));
  }
};

export const getRecommendIOI = (id) => async (dispatch) => {
  try {
    if (id) {
      // //console.log("*******");
      // ioiRouter.get('/users/recommend-ioi/:id',recommendIOI)
      const { data } = await axios.get(
        keyUri.BACKEND_URI + `/users/recommend-ioi-in-individualuser/${id}`,
        config
      );
      // //console.log(data, "++++++++++++++++");
      dispatch(getRecommendationIOI(data));
    }
    // dispatch(fetchIndividualProfile(token));
  } catch (error) {
    toast.warning(`${error.response.data.message}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
};
