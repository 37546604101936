import React from 'react'
import TopicsDisplays from './TopicsDisplays'

function index() {

  
  return (
    <div>
        <TopicsDisplays/>
    </div>
  )
}

export default index