import React, { useEffect } from "react";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  follwIOIProfile,
  individualUserSelector,
  unfollwIOIProfile,
  usersFollowing,
} from "../../api/individualUser";
import { useNavigate } from "react-router";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";

function MyFollowing() {
  const dispatch = useDispatch();
  const { following, user, individualtoken } = useSelector(
    individualUserSelector
  );
  const nav = useNavigate();

  useEffect(() => {
    dispatch(usersFollowing(user?._id));
  }, [user]);


  return (
    <div>
      <section class=" mt-5 py-0" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div className="w-full flex justify-end"></div>
          <div class="flex flex-wrap -m-4">
            {following?.map((item, i) => {
              if(item?.profile_activate_status)
              return (
                <div
                  class="w-full lg:w-[33%] p-2 cursor-pointer"
                  key={i}
                  onClick={() => nav(`/view-ioiprofile/${item?._id}`)}
                >
                  <div class="relative p-2 bg-white shadow rounded-xl">
                  
                    <img
                      class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                      src={
                        item?.profile_completion?.logo
                          ? item?.profile_completion?.logo
                          : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                      }
                      alt=""
                    />
                    <h3 class="text-center font-semibold text-lg text-[#0F3D79] whitespace-nowrap overflow-hidden text-ellipsis">
                      {item?.name}
                    </h3>
                    <p class="mb-5 text-center text-sm text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                    {item?.sub_category}
                    </p>
                    {user?.following?.find((foll) => {
                      return foll?._id === item?._id;
                    }) ? (
                      <div class="text-center">
                        <a
                          class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 hover:hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                              unfollwIOIProfile(
                                user?._id,
                                { id: item?._id },
                                individualtoken
                              )
                            );
                          }}
                        >
                          <span className="">
                            <PersonRemoveIcon />
                          </span>
                          <span class="relative px-3 text-lg">Unfollow</span>
                        </a>
                      </div>
                    ) : (
                      <div class="text-center">
                        <div
                          class="relative group inline-block py-2 px-7 font-semibold text-blue-900 hover:hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            dispatch(
                              follwIOIProfile(
                                user?._id,
                                { id: item?._id },
                                individualtoken
                              )
                            );
                          }}
                        >
                          <span className="">
                            <PersonAddAltRoundedIcon />
                          </span>
                          <span class="relative px-3 text-lg">Follow</span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyFollowing;
