import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import Logo from "../../images/logo.png";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIndividualProfile,
  individualLogin,
  individualUserSelector,
} from "../../api/individualUser";
import { Typography } from "@material-ui/core";
import Image from "../../images/bharc login-04 (1).png";
import { useMediaQuery } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginBottom: theme.spacing(3),
  },
  halfLg: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: theme.breakpoints.values["lg"] / 2,
    },
  },
  firstBox: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.up("lg")]: {
      marginLeft: "auto",
      paddingTop: theme.spacing(30),
      paddingBottom: theme.spacing(0),
      paddingRight: theme.spacing(30),
    },
  },
  fullHeightImage: {
    position: "absolute",
    top: 100,
    left: 0,
    right: 0,
    bottom: 0,
    objectFit: "contain",
    height: "100%",
    width: "100%",
  },
}));

const inititalFormData = {
  email: "",
  password: "",
};

export default function Login() {
  const [showPassword, setShowPassword] = React.useState(false);
  const [formData, setFormData] = useState(inititalFormData);

  const { isAuthenticate, user, individualtoken } = useSelector(
    individualUserSelector
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const classes = useStyles();

  const content = {
    brand: { image: { Logo }, width: 120 },
    "02_secondary-action": "Don't have an account?Sign up here",
    "02_tertiary-action": "Forgot password?",
  };

  let brand;

  if (content.brand.image.Logo) {
    brand = (
      <img src={content.brand.image.Logo} alt="" width={content.brand.width} />
    );
  } else {
    brand = content.brand.text || "";
  }

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isAuthenticate) {
      if (individualtoken) {
        dispatch(fetchIndividualProfile(individualtoken));
      }
    }
  }, [isAuthenticate]);

  useEffect(() => {
    if (user?.profile_completion?.category) {
      navigate("/home");
    } else if (user && isAuthenticate) {
      // navigate("/verify-phone");
      navigate("/home");
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch(
      individualLogin({
        email: formData.email.toLowerCase(),
        password: formData.password,
      })
    );
  };
  const isMobile = useMediaQuery('(max-width: 600px)'); // Adjust the width as needed


  return (
    <div>
      <section style={{ fontFamily: "Lato" }}>
        <Grid container>
        <Grid item xs={12} lg={6}>
          <Box position="relative" height="100%">
            {isMobile ? (
              null // Render nothing for mobile
            ) : (
              <img className={classes.fullHeightImage} src={Image} alt="" />
            )}
          </Box>
        </Grid>
          {/* <Grid item xs={12} lg={6}>
            <Box
              position="relative"
              style={{ height: "100%", marginTop: "15%" }}
            >
              <img className={classes.fullHeightImage} src={Image} alt="" />
            </Box>
          </Grid> */}

          <Grid item xs={12} lg={6}>
            <Box className={[classes.halfLg, classes.firstBox]}>
              <Container maxWidth="xs">
                <form onSubmit={handleSubmit}>
                  <Box pt={8} pb={10}>
                    <Box mb={1} textAlign="center">
                      <div style={{ marginLeft: "30%", marginBottom: "5%" }}>
                        <Link
                          href="/"
                          variant="h4"
                          color="inherit"
                          underline="none"
                        >
                          {brand}
                        </Link>
                      </div>
                      <Box textAlign="center" className="">
                        <Typography
                          variant="h5"
                          component="h2"
                          className="text-center text-[#0F3D79]"
                        >
                          {" "}
                          Individual User login
                        </Typography>
                      </Box>
                    </Box>
                    <div>
                      <div>
                        <FormControl
                          sx={{ width: "100%", marginTop: "10px" }}
                          size="small"
                          variant="outlined"
                        >
                          <InputLabel>Enter Email</InputLabel>
                          <OutlinedInput
                            type="email"
                            required
                            label="Enter Email"
                            name="email" // Make sure to set the name attribute
                            value={formData.email}
                            onChange={handleFormChange}
                            // You can add more attributes or props as needed
                          />
                        </FormControl>
                      </div>

                      <FormControl
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel htmlFor="outlined-adornment-password">
                          Enter Password
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          required
                          label="Enter Password"
                          name="password"
                          value={formData.password}
                          onChange={handleFormChange}
                        />
                      </FormControl>

                      <Grid container spacing={1} className={classes.actions}>
                        <Grid item xs={12} sm={9}>
                          <NavLink to="/sign-up">
                            <Link variant="body2">
                              {content["02_secondary-action"]}
                            </Link>
                          </NavLink>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          className={classes.tertiaryAction}
                        >
                          <Link href="/forgot-password" variant="body2">
                            {content["02_tertiary-action"]}
                          </Link>
                        </Grid>
                      </Grid>
                    </div>

                    <Stack direction="row" spacing={4} my={2}>
                      <Button
                        type="submit"
                        variant="contained"
                        style={{
                          backgroundColor: "#0066A2",
                          color: "white",
                        }}
                      >
                        Log In
                      </Button>
                    </Stack>
                  </Box>
                </form>
              </Container>
            </Box>
          </Grid>
        </Grid>
      </section>
      {/* <p class="mx-2 sm:block md:hidden  text-center text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]">
        "The application is currently supported only in a Desktop or a Laptop."
      </p>
      <div class=" border-t border-gray-200 mt-20">
        
        <div class="lg:flex items-center py-3 float-right md:mr-60 ">
          <div class="flex lg:mb-0 items-center">
         
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              href="/privacy"
            >
              Privacy Policy
            </a>
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              href="/agreement"
            >
              User Agreement
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}
