import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom"; // Corrected import for `react-router-dom`
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import DvrIcon from "@mui/icons-material/Dvr";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink, Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add"; // Corrected import from "@material-ui/icons/Add" to "@mui/icons-material/Add"
import Image1 from "../../images/comingsoon.png";
import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WorkIcon from "@mui/icons-material/Work";
import image2 from "../../images/logo.png";
import { fetchAllPublish, publishSelector } from "../../api/publish"; // Combined import for better readability

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word", // Compatibility with older browsers
  },
}));

function TopicsDisplays() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation(); // Corrected to useLocation from `react-router-dom`
  const classes = useStyles();
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );

  const { publish } = useSelector(publishSelector);

  const [searchQuery, setSearchQuery] = useState("");
  const [type, setType] = useState("topics");

  useEffect(() => {
    dispatch(fetchAllPublish());
  }, []); // Added dispatch to dependency array for best practice

  console.log(user, individualtoken, "individual user");
  console.log(publish, "this is publish");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };
  
  const filetrPublish = publish?.filter((pub) => {
    console.log(pub, "pub");
  
    // Get the user's and IOI's names in lowercase for comparison
    const userName = pub?.publish_posted_by_user?.name?.toLowerCase() || "";
    const ioiName = pub?.publish_posted_by_ioi?.name?.toLowerCase() || "";
  
    // Convert the search query to lowercase
    const query = searchQuery.toLowerCase();
  
    // Check if the query matches either the user's name or the IOI's name
    return userName.includes(query) || ioiName.includes(query);
  });

  return (
    <div>
      <section className="py-8" style={{ fontFamily: "Lato" }}>
        <div className="container px-4 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div className="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div className="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="#">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/publish">
                  <div
                    className={`flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer ${
                      location.pathname.includes("publish")
                        ? "bg-[#CEDDF6]"
                        : "bg-[#F3F4F6]"
                    }`}
                  >
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyUploads">
                  <div className="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div className="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 className="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Uploads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            {/* Right section with search, filter, and create buttons */}
            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      Publish{" "}
                      <span className="text-sm"> - Patent Certificates</span>
                    </h2>
                  </div>

                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    {/* Left-aligned search bar */}
                    <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search individual / company name"
                          className="py-2 pl-10 pr-4 w-full lg:w-1/2 rounded-xl border border-gray-300"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                    {/* Right-aligned filter and create buttons */}
                    <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      <button
                        className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 lg:mr-2 lg:ml-4"
                        type="button"
                      >
                        <FilterIcon /> Filter
                      </button>
                      <Link
                        to="/PublishCertificate"
                        className="w-full sm:w-auto"
                      >
                        <button
                          className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full sm:w-auto"
                          type="button"
                          onClick={reloadButton}
                        >
                          <AddIcon /> Create
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* Topic Cards */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filetrPublish?.length > 0 ? (
                      filetrPublish?.map((item, index) =>
                        item?.publish_posted_by_user ? (
                          <NavLink
                            to={`/PublishView/${item._id}`}
                            key={item._id || index}
                          >
                            <div className="bg-white p-6 rounded-xl shadow-md max-h-[450px] flex flex-col justify-between overflow-hidden h-[400px]">
                              <div className="flex items-center mb-4">
                                <img
                                  src={
                                    item?.publish_posted_by_user?.profile_pic
                                      ? item?.publish_posted_by_user.profile_pic
                                      : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                  }
                                  alt="Profile"
                                  className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                                />
                                <div>
                                  <h3 className="text-[#0F3D79] text-md font-semibold">
                                    {item?.publish_posted_by_user?.name ||
                                      "Name"}
                                  </h3>
                                  <p className="text-sm text-gray-500">
                                    {item?.publish_posted_by_user
                                      ?.profile_completion?.designation ||
                                      "Designation"}
                                  </p>
                                </div>
                              </div>
                              <h4 className="text-[#0F3D79] font-semibold mb-2">
                                {item?.certificate_name ||
                                  "Topic Name Goes Here"}
                              </h4>
                              <p className="text-sm text-gray-600 mb-4">
                                {item?.description.substring(0, 100)}...{" "}
                                <a href="#" className="text-blue-600">
                                  Read more
                                </a>
                              </p>
                              <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                                <PictureAsPdfIcon
                                  style={{ fontSize: 50 }}
                                  className="text-blue-600"
                                />
                              </div>
                            </div>
                          </NavLink>
                        ) : (
                          <NavLink
                          to={`/PublishView/${item._id}`}
                          key={item._id || index}
                        >
                          <div
                            key={index}
                            className="bg-white p-6 rounded-xl shadow-md max-h-[450px] flex flex-col justify-between overflow-hidden h-[400px]"
                          >
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  item?.publish_posted_by_ioi
                                    ?.profile_completion?.logo
                                    ? item?.publish_posted_by_ioi
                                        .profile_completion.logo
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {item?.publish_posted_by_ioi?.name || "Name"}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {item?.publish_posted_by_ioi?.sub_category ||
                                    "Category"}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {item?.certificate_name || "Certification Name"}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {item?.description || "Description"}{" "}
                              <a href="#" className="text-blue-600">
                                Read more
                              </a>
                            </p>
                            <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                              <PictureAsPdfIcon
                                style={{ fontSize: 50 }}
                                className="text-blue-600"
                              />
                            </div>
                          </div>
                          </NavLink>
                        )
                      )
                    ) : (
                      <p>No data available</p> // Fallback content if `publish` array is empty
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopicsDisplays;
