import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { keyUri, config } from "../key";
import { toast } from "react-toastify";

// Retrieve token from local storage
const token = localStorage.getItem("token") || null;

// Define the initial state
export const initialState = {
  loading: false,
  hasErrors: false,
  explore: [],
  currentExplore: {},
  individualtoken: token,
};

// Redux slice for explore management
export const exploreSlice = createSlice({
  name: "explore",
  initialState,
  reducers: {
    getExploreLoading: (state) => {
      state.loading = true;
      state.hasErrors = false; // Reset error state on new request
    },
    getCurrentExploreSuccessfull: (state, { payload }) => {
      state.loading = false;
      state.currentExplore = payload?.explore || {};
      state.hasErrors = false;
    },
    getAllExploreSuccessful: (state, { payload }) => {
      state.loading = false;
      state.explore = payload?.all_explore || [];
      state.hasErrors = false;
    },
    getExploreFailed: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getExploreSuccess: (state, { payload }) => {
      state.loading = false;
      // You might want to add a specific action for success with additional state changes if needed
    },
  },
});

// Export actions
export const {
  getExploreLoading,
  getAllExploreSuccessful,
  getCurrentExploreSuccessfull,
  getExploreFailed,
  getExploreSuccess,
} = exploreSlice.actions;

// Selector to access explore state
export const exploreSelector = (state) => state.explore;

// Export reducer
export default exploreSlice.reducer;

// Thunks for API calls

// Fetch all explores
export const fetchAllExplore = () => async (dispatch) => {
  dispatch(getExploreLoading());
  try {
    const { data } = await axios.get(
      `${keyUri.BACKEND_URI}/ioi-users/fetch-events`,
      config
    );
    dispatch(getAllExploreSuccessful(data));
    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log("Fetched all explores:", data);
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to fetch explores.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};

// Create a new explore
export const createExplore = (value) => async (dispatch) => {
  dispatch(getExploreLoading());

  try {
    const { data } = await axios.post(
      `${keyUri.BACKEND_URI}/ioi-users/create-events`,
      value,
      config
    );

    toast.success(data.message, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    console.log("Created new explore:", data);

    // Wait for 3 seconds before performing the next operation
    setTimeout(() => {
      // Perform your next operation here
      // Example: Dispatch another action or update the local state
      dispatch(getExploreSuccess(data)); // Example action dispatch
    }, 3000); // 3 seconds delay
  } catch (error) {
    const errorMessage =
      error?.response?.data?.message || "Failed to create explore.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    dispatch(getExploreFailed());
  }
};
