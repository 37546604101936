import React, { useEffect, useState } from "react";
import "./Topics.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import DvrIcon from "@mui/icons-material/Dvr";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Addtopics from "./Addtopics";
import Image1 from "../../images/comingsoon.png";

import FilterIcon from "@mui/icons-material/FilterList";
// import AddIcon from '@mui/icons-material/Add';
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import WorkIcon from "@mui/icons-material/Work";
import image2 from "../../images/logo.png";
import pdfff from "../../images/pdff.pdf";
import { topicSelector } from "../../api/topic";
import { fetchOneTopic, deleteTopic} from "../../api/topic";
import { useNavigate, useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

function TopicView() {
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );
  const dispatch = useDispatch();
  const nav = useNavigate();
  const { id } = useParams();
  const { pathname } = useLocation();

  const { topics, currentTopic } = useSelector(topicSelector);
  console.log(topics, "view");



  console.log(id, "id");

  useEffect(() => {
    dispatch(fetchOneTopic(id));
  }, [id]);


const handleDeleteTopic = (deleteId) => {
  dispatch(deleteTopic(deleteId))
  nav("/topics");
}


  console.log(topics, "view");
  console.log(currentTopic, "current topic view");

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="/topics">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("topics")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        All Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Mytopics">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Topics
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    <div className="m-4 d-flex">
                      <h2 className="text-lg font-semibold text-[#0F3D79]">
                        Topics
                      </h2>
                    </div>

                    <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      {user?._id === currentTopic?.topic_posted_by?._id ? (
                       
                          <button
                            className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full sm:w-auto"
                            type="button"
                            onClick={() => handleDeleteTopic(id)}
                          >
                            <AddIcon /> Delete
                          </button>
                       
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                 
                  <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center ">
                    <div className="w-full lg:w-1/2 bg-white mb-4 lg:mb-0 h-[500px]">
                      <div className="border p-4 rounded-md h-[500px]">
                        <div className="flex items-center mb-4">
                          <img
                            src={
                              currentTopic?.topic_posted_by?.profile_pic
                                ? currentTopic?.topic_posted_by?.profile_pic
                                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                            }
                            alt="Profile"
                            className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                          />
                          <div>
                            <h3 className="text-[#0F3D79] text-md font-semibold">
                              {currentTopic?.topic_posted_by?.name}
                            </h3>
                            <p className="text-sm text-gray-500">
                              {currentTopic?.topic_posted_by?.profile_completion
                                ?.designation || ""}
                            </p>
                          </div>
                        </div>

                        <h4 className="mt-4 text-[#0F3D79] text-md font-semibold">
                          {currentTopic?.name || ""}
                        </h4>
                        <p className="mt-2 text-gray-700">
                          {currentTopic?.summary || ""}
                        </p>
                      </div>
                    </div>

                    {/* PDF Display */}
                    <div className="w-full lg:w-1/2 flex justify-center ml-2">
                      <iframe
                        src={
                          currentTopic?.document
                            ? currentTopic?.document
                            : pdfff
                        } // Replace with your PDF URL
                        width="100%"
                        height="500px" // Adjust the height as needed
                        style={{ border: "none", backgroundColor: "white" }}
                        title="PDF Viewer"
                      />
                    </div>
                  </div>
                  {/* 
                  }
                */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default TopicView;
