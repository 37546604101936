import React from 'react'
import notFound from '../images/404.png'

function NotFound() {
  return (
    <div className='flex justify-center item-center mt-[20vh]'>
      <p className='text-4xl font-bold'>404 Page not found</p>
      <img src = {notFound} className='pt-20'/>
    </div>
  )
}

export default NotFound