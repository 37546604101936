import React from 'react'
import ExploreDisplays from './ExploreDisplays'

function index() {
  return (
    <div>
        <ExploreDisplays/>
    </div>
  )
}

export default index