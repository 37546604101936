import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import DvrIcon from "@mui/icons-material/Dvr";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Image1 from "../../images/comingsoon.png";

import FilterIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search"; // Import the search icon
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import a PDF icon
import WorkIcon from "@mui/icons-material/Work";
import image2 from "../../images/logo.png";
import { set } from "date-fns";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

function MyUploads() {
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );
  const [publish, setPublish] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { pathname } = useLocation();

  console.log(user, "iser");

  useEffect(() => {
    if (user) {
      // Assuming user.topics is an array of topics
      const reversedPublishes = (user?.publish || []).slice().reverse(); // Reverse the array to show last added topic first
      setPublish(reversedPublishes);
    }
  }, [user]);
  

  // Function to handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };




  const filteredPublish = publish?.filter((pub, index) => {
    console.log(pub, "pub");
    const cert = pub?.certificate_name.toLowerCase();
    const postedByName = user?.name.toLowerCase();
    const query = searchQuery.toLowerCase();
    return cert.includes(query) || postedByName.includes(query);
  });

  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
                <NavLink to="#">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        General Documents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/publish">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Patent Certificates
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        Research papers
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/MyUploads">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                    style={{
                      backgroundColor: pathname.includes("MyUploads")
                        ? "#CEDDF6"
                        : "#F3F4F6",
                    }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <WorkIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                        My Uploads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>

            <div className="w-full lg:w-[78%] px-3">
              <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
                <div className="md:max-w-6xl mx-auto">
                  {/* Header */}
                  <div className="m-4">
                    <h2 className="text-lg font-semibold text-[#0F3D79]">
                      My Uploads
                    </h2>
                  </div>

                  <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
                    {/* Left-aligned search bar */}
                    <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
                      <div className="relative">
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon className="text-gray-500" />
                        </span>
                        <input

                          type="text"
                          placeholder="Search topic name"
                          className="py-2 pl-10 pr-4 w-full lg:w-1/2 rounded-xl border border-gray-300"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                      </div>
                    </div>
                    {/* Right-aligned filter and create buttons */}
                    <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
                      <button
                        className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 lg:mr-2 lg:ml-4"
                        type="button"
                      >
                        <FilterIcon /> Filter
                      </button>
                      <Link
                        to="/PublishCertificate"
                        className="w-full sm:w-auto"
                      >
                        <button
                          className="py-2 px-4 text-white font-semibold border rounded-xl bg-[#0F3D79] w-full sm:w-auto"
                          type="button"
                          onClick={reloadButton}
                        >
                          <AddIcon /> Create
                        </button>
                      </Link>
                    </div>
                  </div>

                  {/* Topic Cards */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredPublish?.length > 0 ? (
                      filteredPublish?.map((publish) => (
                        <NavLink
                          key={publish?._id}
                          to={`/PublishView/${publish?._id}`}
                        >
                          <div className="bg-white p-6 rounded-xl shadow-md h-[400px]">
                            <div className="flex items-center mb-4">
                              <img
                                src={
                                  user?.profile_pic
                                    ? user?.profile_pic
                                    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                }
                                alt="Profile"
                                className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                              />
                              <div>
                                <h3 className="text-[#0F3D79] text-md font-semibold">
                                  {user.name}
                                </h3>
                                <p className="text-sm text-gray-500">
                                  {user.profile_completion?.designation}
                                </p>
                              </div>
                            </div>
                            <h4 className="text-[#0F3D79] font-semibold mb-2">
                              {publish.certificate_name}
                            </h4>
                            <p className="text-sm text-gray-600 mb-4">
                              {publish.description.substring(0, 100)}...{" "}
                              <a href="#" className="text-blue-600">
                                Read more
                              </a>
                            </p>
                            <div className="flex justify-center items-center h-32 bg-gray-100 rounded-lg">
                              <PictureAsPdfIcon
                                style={{ fontSize: 50 }}
                                className="text-blue-600"
                              />
                            </div>
                          </div>
                        </NavLink>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default MyUploads;
