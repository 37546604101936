import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import AddTaskRoundedIcon from "@mui/icons-material/AddTaskRounded";
import {
  acceptConnectionRequest,
  declineConnectionRequest,
  follwIOIProfile,
  individualUserSelector,
  paymentValidate,
  sendConnectReq,
  unfollwIOIProfile,
  fetchIndividualProfile,
  recommendPeople,
  setType,
} from "../../api/individualUser";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import ProfilePic from "./ProfilePic";
import EducationDetails from "./EducationDetails";
import CarrerObjective from "./CarrerObjective";
import Awards from "./Awards";
import Achivements from "./Achivements";
import WorkExperiance from "./WorkExperiance";
import Skills from "./Skills";
import Image from "../../images/DomesticSolutions-16.jpg";
import Image1 from "../../images/AIforAI-17.png";
import Image2 from "../../images/DreamTrue-18.jpg";
import Image3 from "../../images/brodcast.jpeg";
import PopUp from "../../components/popup";
import useRazorpay from "react-razorpay";
import { keyUri, config } from "../../key";
import axios from "axios";
import PopUp1 from "../../components/popup1";
import logo from "../../images/slogo.png";
import io from "socket.io-client";
var socket, selectedChatCompare;

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

export default function Section3({ connected, following }) {
  const dispatch = useDispatch();

  const [socket, setSocket] = useState(null);
  const nav = useNavigate();
  const [Razorpay] = useRazorpay();
  const [timeLeft, setTimeLeft] = useState(3 * 60); // 48 hours in seconds
  const [period, setPeriod] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const { user, individualtoken } = useSelector(individualUserSelector);

  // const lastFetchTime = useRef(Date.now());

  const fetchPeriod = async (id) => {
    try {
      const response = await axios.get(
        `${keyUri.BACKEND_URI}/users/period/${id}`
      );
      setPeriod(response.data.period);
      // //console.log(response.data.period, "response period");
    } catch (error) {
      console.error("Error fetching period:", error);
    }
  };


  console.log(user, "this is user")
  useEffect(() => {
    if (user?._id) {
      fetchPeriod(user?._id); // Initial fetch
    }
  }, [user?._id]);

  useEffect(() => {
    // //console.log("888");
    if (individualtoken) {
      dispatch(fetchIndividualProfile(individualtoken));
    }
  }, [individualtoken]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs}h ${mins}m ${secs}s`;
  };

  useEffect(() => {
    amountCalculation();
  }, [user]);

  function amountCalculation() {
    if (user?.profile_completion.category === "Student") {
      setPaymentAmount(user?.plan[0]?.price);
    } else {
      setPaymentAmount(user?.plan[0]?.price);
    }
  }

  const receiptId = "qwsaq1";
  const currencyInr = "INR";

  //   // //console.log(window.Razorpay);
  //   // //console.log(user.email);
  //   try {
  //     const finalAmt = Number(paymentAmount) * 100;
  //     // //console.log("Sending payment request...");

  //     const response = await fetch(`${keyUri.BACKEND_URI}/order`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         amount: finalAmt,
  //         currency: currencyInr,
  //         receipt: receiptId,
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     });

  //     const order = await response.json();
  //     // //console.log("Order created:", order);

  //     const options = {
  //       key: "rzp_live_DdtLCdVoEvVBt1",
  //       amount: finalAmt,
  //       currency: currencyInr,
  //       name: "BHARC",
  //       description: "Test Transaction",
  //       image: "../images/logo.png",
  //       order_id: order.id,
  //       handler: async function (response) {
  //         const body = {
  //           ...response,
  //           email: user.email,
  //           amount: finalAmt,
  //           currency: currencyInr,
  //         };
  //         const validateRes = await fetch(`${keyUri.BACKEND_URI}/order/validate`, {
  //           method: "POST",
  //           body: JSON.stringify(body),
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         });
  //         setPeriod(user.period)
  //         const jsonRes = await validateRes.json();

  //         // //console.log(jsonRes, "this is json response validate");
  //       },
  //       prefill: {
  //         name: "BHARC",
  //         email: "info@bharc.in",
  //         contact: "1234567899",
  //       },
  //       theme: {
  //         color: "#e0f3fa",
  //       },
  //     };

  //     const rzp1 = new Razorpay(options);
  //     rzp1.on('payment.failed', function (response) {
  //       alert(response.error.code);
  //       alert(response.error.description);
  //       alert(response.error.source);
  //       alert(response.error.step);
  //       alert(response.error.reason);
  //       alert(response.error.metadata.order_id);
  //       alert(response.error.metadata.payment_id);
  //     });

  //     rzp1.open();
  //   } catch (error) {
  //     console.error("Error creating order:", error);
  //   }
  // };

  // useEffect(() => {
  //   // //console.log("web socket connection");
  //   const ws = new WebSocket('ws://localhost:5000/api'); // Update the URL if necessary

  //   ws.onopen = () => {
  //     // //console.log('WebSocket connection established');
  //   };

  //   ws.onmessage = (event) => {
  //     // //console.log('Message received from server:', event.data);

  //     const data = JSON.parse(event.data);
  //     // //console.log(data, "datatype");

  //     if (data.type === 'PERIOD_UPDATE') {
  //       setPeriod((prevPeriod) => {
  //         if (prevPeriod !== data.period) {
  //           // //console.log('Period update received:', data.period);
  //           return data.period;
  //         }
  //         return prevPeriod;
  //       });
  //     }
  //   };

  //   ws.onclose = () => {
  //     // //console.log('WebSocket connection closed');
  //   };

  //   ws.onerror = (error) => {
  //     console.error('WebSocket error:', error);
  //   };

  //   setSocket(ws);

  //   return () => {
  //     if (ws) {
  //       ws.close();
  //     }
  //   };
  // }, []);

  const handlePayment = async () => {
    // //console.log(window.Razorpay);
    // //console.log(user.email);

    try {
      const finalAmt = Number(paymentAmount) * 100; // Convert amount to smallest unit (e.g., cents)
      // //console.log("Sending payment request...");

      // Create the order on the backend
      const response = await fetch(`${keyUri.BACKEND_URI}/order`, {
        method: "POST",
        body: JSON.stringify({
          amount: finalAmt,
          currency: currencyInr,
          receipt: receiptId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const order = await response.json();
      // //console.log("Order created:", order);

      // Setup Razorpay options
      const options = {
        key: "rzp_live_DdtLCdVoEvVBt1", // Your Razorpay Key ID
        amount: finalAmt,
        currency: currencyInr,
        name: "BHARAT RESEARCHERS CLOUD",
        description: "Test Transaction",
        image: logo,
        order_id: order.id,
        handler: async function (response) {
          // Validate payment on the server
          const body = {
            ...response,
            email: user.email,
            amount: finalAmt,
            currency: currencyInr,
          };

          const validateRes = await fetch(
            `${keyUri.BACKEND_URI}/order/validate`,
            {
              method: "POST",
              body: JSON.stringify(body),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          // Parse server response
          const jsonRes = await validateRes.json();
          // //console.log(jsonRes, "this is json response validate");

          if (jsonRes.success) {
            // Assuming success indicates successful validation
            // Fetch the updated period and update state
            fetchPeriod(user._id);
          }
        },
        prefill: {
          name: "BHARAT RESEARCHERS CLOUD",
          email: "info@bharc.in",
        },
        theme: {
          color: "#e0f3fa",
        },
      };

      // Initialize and open Razorpay payment gateway
      const rzp1 = new Razorpay(options);
      rzp1.on("payment.failed", function (response) {
        alert(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });

      rzp1.open();
    } catch (error) {
      console.error("Error creating order:", error);
    }
  };

  useEffect(() => {
    console.log("web socket connection");
    const ws = new WebSocket(
      "ws://king-prawn-app-p955d.ondigitalocean.app/api"
      // "ws://localhost:5000/api"
    );

    ws.onopen = () => {
      console.log("WebSocket connection established");
    };

    ws.onmessage = (event) => {
      console.log("Message received from server:", event.data);

      const data = JSON.parse(event.data);
      console.log(data.userId, "datatype");
      console.log(user._id, "user id")

      console.log(period, "periodod")

      if (data.type === "PERIOD_UPDATE" && data.userId === user._id) {
        
        setPeriod((prevPeriod) => {
          console.log(prevPeriod, "prev period")
          if (prevPeriod !== data.period) {
            console.log("Period update received:", data.period);
            return data.period;
          }
          return prevPeriod;
        });
      }
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    setSocket(ws);

    return () => {
      if (ws) {
        ws.close();
      }
    };
  }, [user?._id]);

  return (
    <div style={{ fontFamily: "Lato" }}>
      {period === 1 && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#F3F4F6",
            padding: "20px 20px",
            color: "#000",
            marginTop: "15px",
          }}
        >
          <div style={{ flexBasis: "80%" }}>
            <h3
              style={{
                marginBottom: "10px",
                fontSize: "22px",
                color: "#0F3D79",
                fontWeight: "600",
              }}
            >
              Free Period Activated
            </h3>
            <p
              style={{
                marginBottom: "25px",
                fontSize: "15px",
                color: "#000000",
              }}
            >
              Your free period has started! You have {user?.plan[0]?.freePeriod} days to explore all
              features. Remember, subscribe within this period to continue
              uninterrupted access after the free period.
            </p>
            <div
              onClick={() => handlePayment()}
              className="py-2 px-7 border font-semibold text-white rounded-full bg-[#0F3D79]  transition duration-300 overflow-hidden cursor-pointer hover:bg-white hover:text-[#0F3D79] w-[160px]"
            >
              <span>Try Premium</span>
            </div>
          </div>
        </div>
      )}
      {period === 2 && (
        <div
          style={{
            backgroundColor: "#F3F4F6",
            color: "#fff",
            padding: "15px",
            margin: "16px",
          }}
        >
          <h5
            style={{
              marginBottom: "10px",
              fontSize: "20px",
              color: "#0F3D79",
              fontWeight: "600",
            }}
          >
            You are on your {user?.plan[0]?.trialPeriod} days Trial Period
          </h5>
          <p
            style={{ marginBottom: "5px", fontSize: "15px", color: "#000000" }}
          >
            Congratulations on subscribing! You now have a {user?.plan[0]?.trialPeriod} days of trial period
            to explore all features. Your subscription will automatically start
            after this trial period ends. Enjoy uninterrupted access!
          </p>
        </div>
      )}
      {period === 3 && (
        <div
          style={{
            backgroundColor: "#F3F4F6",
            color: "#fff",
            padding: "15px",
            margin: "16px",
          }}
        >
          <h5
            style={{
              marginBottom: "10px",
              fontSize: "20px",
              color: "#0F3D79",
              fontWeight: "600",
            }}
          >
            Subscription Activated
          </h5>
          <p
            style={{ marginBottom: "5px", fontSize: "15px", color: "#000000" }}
          >
            Your subscription has been confirmed! Enjoy uninterrupted access
            starting from today.
          </p>
        </div>
      )}
      {period === 0 && <PopUp1 handlePayment={handlePayment} />}
      {period === 99 && <PopUp handlePayment={handlePayment} />}

      <section class="pt-10">
        <div class="container" style={{ margin: "auto" }}>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-[25%] px-3">
              <ProfilePic user={user} individualtoken={individualtoken} />

              <div class="py-2 bg-gray-100 rounded-xl overflow-hidden mb-5 h-[341px] hidden sm:block">
                <div className="flex flex-wrap justify-between ">
                  <h3
                    class="text-lg font-semibold text-[#0F3D79] mb-4"
                    style={{ marginLeft: "15px", marginTop: "10px" }}
                  >
                    Projects
                  </h3>
                </div>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/bharc"
                >
                  <img className="h-12 w-[135px] object-cover" src={Image3} />
                </Link>

                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/domesticSolutions"
                >
                  <img className="h-12" src={Image} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/AI"
                >
                  <img className="h-12" src={Image1} />
                </Link>
                <Link
                  class="flex group py-3 px-2 sm:px-16 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200"
                  to="/dreamTrue"
                >
                  <img className="h-12" src={Image2} />
                </Link>
              </div>
            </div>

            <div
              class="w-full lg:w-[43%] px-3"
              // style={{ display: type == "" ? "block" : "none" }}
            >
              <div class="container mx-auto">
                <div class="relative pt-3 px-4 py-3 bg-gray-100 shadow rounded-lg overflow-hidden lg:h-[300px]">
                  <div>
                    <EducationDetails
                      user={user}
                      individualtoken={individualtoken}
                    />

                    {/* 
                    <div class="flex flex-wrap -mx-4 -mb-4 justify-between items-center">
                      <div class="w-auto px-4">
                        <h3 class="text-lg font-semibold text-[#0F3D79] mb-3">
                          Interested Domains
                        </h3>
                      </div>
                      {user?.profile_completion?.skill?.length == 0 ? (
                        <button class="w-auto px-2 mb-3 text-gray-500">
                          <AddIcon onClick={() => setOpen(true)} />
                        </button>
                      ) : (
                        <button class="w-auto px-2 mb-3 text-gray-300 hover:text-gray-500">
                          <EditIcon onClick={() => setOpen(true)} />
                        </button>
                      )}
                      <div class="w-full">
                        <div class="h-full py-7 px-4 rounded-xl">
                          <div class="mb-8 -mx-4">
                            <div class="-mb-3 text-start white-space-no-wrap">
                              {user?.profile_completion?.skills.map(
                                (item, i) => {
                                  return (
                                    i < 6 && (
                                      <p class="px-2 pl-5 py-1 my-1 mb-1 text-gray-500 bg-gray-200 text-sm font-medium leading-6 rounded-full">
                                        {item}
                                      </p>
                                    )
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* -----------------------------------------------------connected------------------------------------------------------------- */}
                {/* <div class="w-full mt-5 mb-6 lg:mb-0">
                    <div class="pt-6 pb-6 px-6 bg-gray-100 rounded-xl  h-[343px]">
                        <div className="flex flex-wrap justify-between">
                            <h3 class="text-lg font-semibold text-[#0F3D79] mb-4">
                                Connections
                            </h3>
                            <button
                                class="justify-between px-4 mb-10 text-[#0F3D79] hover:bg-slate-200 font-semibold"
                                onClick={() => nav("/connection")}
                            >
                                View more
                            </button>
                        </div>
                        <div class="flex flex-wrap -m-4 cursor-pointer">
                            {connected?.map((item, i) => {
                                if (i < 3 && item?.profile_activate_status) {
                                return (
                                    <div key={i} class="w-full md:w-1/3 lg:w-1/3 p-1">
                                    <div class="relative p-2 bg-white shadow rounded-xl">
                                        <img
                                        onClick={() => nav(`/profile/${item?._id}`)}
                                        class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                                        src={item?.profile_completion?.profile_pic}
                                        alt=""
                                        />
                                        <h3 class="text-center font-semibold text-md text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                                        {item?.name}
                                        </h3>
                                        <h3 class="text-center text-sm text-gray-500   mt-1 mb-2 whitespace-nowrap overflow-hidden text-ellipsis">
                                        {item?.profile_completion?.category ===
                                        "Student"
                                            ? "Student"
                                            : item?.profile_completion?.designation
                                                ?.charAt(0)
                                                .toUpperCase() +
                                            item?.profile_completion?.designation?.slice(
                                                1
                                            )}
                                        </h3>
                                        {user?.connections?.find(
                                        (connection) =>
                                            connection?.user == item._id &&
                                            connection.status === "connected"
                                        ) ? (
                                        <div class="text-center">
                                            <button class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden hover:text-white hover:bg-blue-900">
                                            <span class="relative px-3 text-lg">
                                                Connected
                                            </span>
                                            </button>
                                        </div>
                                        ) : user?.connections?.find((connection) => {
                                            return (
                                            connection.status === "request sent" &&
                                            connection.user == item._id
                                            );
                                        }) ? (
                                        <div class="text-center">
                                            <button class="relative group inline-block py-2 px-7 border font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden  hover:text-white hover:bg-blue-900">
                                            <span className="text-sm">
                                                <AddTaskRoundedIcon />
                                            </span>
                                            <span class="relative px-3 text-lg">
                                                Requested
                                            </span>
                                            </button>
                                        </div>
                                        ) : user?.connections?.find((connection) => {
                                            return (
                                            connection.status ===
                                                "request received" &&
                                            connection.user == item._id
                                            );
                                        }) ? (
                                        <div className="flex gap-2 justify-center">
                                            <div class="text-center">
                                            <button
                                                class="relative group inline-block py-2 px-2 border font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden  hover:text-white hover:bg-blue-900 "
                                                onClick={(e) => {
                                                e.stopPropagation();
                                                acptHandel(user?._id, item._id);
                                                }}
                                            >
                                                <span class="relative px-3 text-md">
                                                Accept
                                                </span>
                                            </button>
                                            </div>
                                            <div class="text-center">
                                            <button
                                                class="relative group inline-block py-2 px-3 border font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden  hover:text-white hover:bg-blue-900 "
                                                onClick={(e) => {
                                                e.stopPropagation();
                                                declineHandel(user?._id, item._id);
                                                }}
                                            >
                                                <span class="relative px-3 text-md">
                                                Decline
                                                </span>
                                            </button>
                                            </div>
                                        </div>
                                        ) : (
                                        <div class="text-center">
                                            <button
                                            class="relative group inline-block py-2 px-7 font-semibold text-blue-900 rounded-full bg-white transition duration-300 overflow-hidden  hover:text-white hover:bg-blue-900 "
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                reqHandel(user?._id, item._id);
                                            }}
                                            >
                                            <span className="">
                                                <SensorOccupiedIcon />
                                            </span>
                                            <span class="relative px-3 text-lg">
                                                Connect
                                            </span>
                                            </button>
                                        </div>
                                        )}
                                    </div>
                                    </div>
                                );
                            }
                        })}
                        </div>
                    </div>
                </div> */}
                {/* -----------------------------------------------------Career Details------------------------------------------------------------- */}
                {/* {
                ) : (
                  <div class="pt-6 pb-6 px-6 bg-gray-100 rounded-xl  h-[343px]">
                  
                  </div>
                )} */}
                {user?.profile_completion?.category === "Student" ? (
                  <div class="w-full mt-5 mb-6 lg:mb-0">
                    <div class="pt-6 pb-6 px-6 bg-gray-100 rounded-xl  h-[343px]">
                      <CarrerObjective
                        user={user}
                        individualtoken={individualtoken}
                      />
                    </div>
                  </div>
                ) : (
                  <div class="w-full mt-5 mb-6 lg:mb-0">
                    <div class="pt-6 pb-6 px-6 bg-gray-100 rounded-xl  h-[343px]">
                      <WorkExperiance
                        user={user}
                        individualtoken={individualtoken}
                      />
                    </div>
                  </div>
                )}
                {/* -----------------------------------------------------Follow------------------------------------------------------------- */}
              </div>
            </div>

            <div
              class="w-full lg:w-[32%] px-3"
              // style={{ display: type == "" ? "block" : "none" }}
            >
              <Skills user={user} individualtoken={individualtoken} />
              <Achivements user={user} individualtoken={individualtoken} />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-[68%] px-3">
              <div className="p-4 bg-gray-100 rounded-xl overflow-hidden mb-5 h-[365px] hidden sm:block">
                <div className="flex flex-wrap justify-between">
                  <h3 className="text-lg font-semibold text-[#0F3D79] mb-4">
                    Following
                  </h3>
                  <button
                    className="text-md font-semibold text-[#0F3D79] mb-4"
                    onClick={() => {
                      dispatch(setType("followings"));
                      nav("/connection");
                    }}
                  >
                    {" "}
                    View More
                  </button>
                </div>

                <div class="flex flex-wrap p-4 -m-4 cursor-pointer">
                  {following?.map((item, i) => {
                    if (i < 3 && item?.profile_activate_status) {
                      return (
                        <div key={i} class="w-full md:w-1/3 lg:w-1/3 p-1">
                          <div class="relative p-4 bg-white shadow rounded-xl">
                            <img
                              onClick={() =>
                                nav(`/view-ioiprofile/${item?._id}`)
                              }
                              class="w-32 h-32 p-1 mb-2 mx-auto rounded-full border border-indigo-50"
                              src={
                                item?.profile_completion?.logo
                                  ? item?.profile_completion?.logo
                                  : "https://www.pitzer.edu/staffcouncil/wp-content/uploads/sites/47/2021/11/nonprofile.png"
                              }
                              alt=""
                            />
                            <h3 class="text-center font-semibold text-md text-gray-800 whitespace-nowrap overflow-hidden text-ellipsis">
                              {item?.name}
                            </h3>
                            <p class="mb-5 text-center text-md text-gray-500 px-4 whitespace-nowrap overflow-hidden text-ellipsis">
                              {item?.sub_category}
                            </p>
                            {user?.following?.find((foll) => {
                              return foll?._id === item?._id;
                            }) ? (
                              <div class="text-center">
                                <a
                                  class="relative group inline-block py-2 px-7 border font-semibold text-[#0F3D79] hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                      unfollwIOIProfile(
                                        user?._id,
                                        { id: item?._id },
                                        individualtoken
                                      )
                                    );
                                  }}
                                >
                                  <span className="">
                                    <PersonRemoveIcon />
                                  </span>
                                  <span class="relative px-3 text-md">
                                    Unfollow
                                  </span>
                                </a>
                              </div>
                            ) : (
                              <div class="text-center">
                                <div
                                  class="relative group inline-block py-2 px-7 border font-semibold text-[#0F3D79] hover:bg-[#0F3D79] hover:text-white rounded-full bg-white transition duration-300 overflow-hidden cursor-pointer"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    dispatch(
                                      follwIOIProfile(
                                        user?._id,
                                        { id: item?._id },
                                        individualtoken
                                      )
                                    );
                                  }}
                                >
                                  <span className="">
                                    <PersonAddAltRoundedIcon />
                                  </span>
                                  <span class="relative px-3 text-lg">
                                    Follow
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
            <div class="w-full lg:w-[32%] px-3 mt-4 sm:mt-5 md:mt-0 lg:mt-0">
              <div>
                <Awards user={user} individualtoken={individualtoken} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
