import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { keyUri } from "../../key";
import { toast } from "react-toastify";

export default function ForgotPassword() {
  const [formData, setFormData] = useState({
    email:'',
    unique_id:''    
  })
 const navigate = useNavigate()

  const content = {
    brand: { image: { Logo }, width: 120 },
    "02_secondary-action": "Back to login page",
    "02_tertiary-action": "Forgot password?",
  };

  const handleFormChange = (e) =>{
    setFormData({...formData,[ e.target.name ]: e.target.value})
  }


  let brand;

  if (content.brand.image.Logo) {
    brand = (
      <img src={content.brand.image.Logo} alt="" width={content.brand.width} />
    );
  } else {
    brand = content.brand.text || "";
  }


  const onFinish = async (e) => { 
    e.preventDefault()
    try{
      const {data} = await axios.post(`${keyUri.BACKEND_URI}/users/forgot-password`,formData)
      navigate('/')
      
      toast.success(`${data?.message}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeButton:false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }catch(error){
      toast.error(`${error?.response?.data?.message}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeButton:false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }

  return (
    <div>
          <Box className=''>
            <Container maxWidth="xs">
              <form onSubmit={onFinish}>
                <Box pt={8} pb={10}>
                  <Box mb={1} textAlign="center">
                    <div style={{ marginLeft: "30%", marginBottom: "5%", marginTop: '30%' }}>
                      <Link
                        href="/"
                        variant="h4"
                        color="inherit"
                        underline="none"
                      >
                        {brand}
                      </Link>
                    </div>
                    <Box textAlign="center" className=''>
            <Typography className='text-center text-[#0F3D79]'>
              Enter the email address associated with your account and we'll send you a link to reset your password
            </Typography>
          </Box>
                  </Box>
          
                    <div>
                      <FormControl
                        sx={{ width: "100%", marginTop: "10px" }}
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel>Enter Email</InputLabel>
                        <OutlinedInput
                          type="email"
                          required
                          label="Enter Email"
                          name="email"
                          value={formData.email}
                          onChange={handleFormChange}
                        />
                      </FormControl>

                      <FormControl
                      sx={{ width: "100%", marginTop: "10px" }}
                      size="small"
                      variant="outlined"
                    >
                      <InputLabel htmlFor="outlined-adornment-text">
                        Enter Unique id
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-text"
                        type="text"
                        required
                        label="Enter Unique id"
                        name="unique_id"
                        value={formData.unique_id}
                        onChange={handleFormChange}
                     
                      />
                    </FormControl>

                    <Stack direction="row" spacing={3} my={2}>
                      <Grid item xs={5} sm={5}>
                        <NavLink to="/">
                          <Link variant="body2">
                            {content["02_secondary-action"]}
                          </Link>
                        </NavLink>
                      </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        fontSize:"12px",
                        backgroundColor: "#0066A2",
                        color: "white",
                      }}
                    >
                     Send Link to this email
                    </Button>
                  </Stack>
                    </div>
                </Box>
              </form>
            </Container>
          </Box>
          <div class=" border-t border-gray-200 mt-40 md:mt-80">
        
        <div class="lg:flex items-center py-3 float-right md:mr-60 ">
          <div class="flex lg:mb-0 items-center">
         
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              href="/privacy"
            >
              Privacy Policy
            </a>
            <a
              class="inline-block mr-4 sm:mr-10 text-lg font-semibold text-[#0F3D79] hover:text-[#0F3D79]"
              target="_blank"
              href="/agreement"
            >
              User Agreement
            </a>
          </div>
        </div>
    </div>
    </div>
  );
}
