import { useDispatch, useSelector } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import {
  fetchIndividualProfile,
  individualUserSelector,
} from "../api/individualUser";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const { isAuthenticate, individualtoken } = useSelector(
    individualUserSelector
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (individualtoken) {
      dispatch(fetchIndividualProfile(individualtoken));
    }
  }, [isAuthenticate]);

  //       useEffect(() => {
  //         if(individualtoken){
  //             const intervalId = setInterval(() => {
  //       // //console.log("its callling ")
  //       fetchIndividualProfile(individualtoken);
  //     }, 6000); // 6000 milliseconds = 6 seconds

  //     // Clean up interval on component unmount
  //     return () => clearInterval(intervalId);
  //         }

  //   }, []);

  return isAuthenticate ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
