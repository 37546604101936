import React from 'react'
import { useNavigate } from 'react-router'

function ProfileLogoInd({user}) {
  const nav = useNavigate()   


  return (
    <div>
        <div class="mb-6">
                <div class=" pb-10 relative bg-gray-100 shadow rounded-lg overflow-hidden h-80">
                  <img
                  onClick={()=> nav('/home')}
                    class="relative w-32 h-32 mb-4 mt-8 mx-auto rounded-full object-cover object-right cursor-pointer"
                    src={
                        user?.profile_completion?.profile_pic
                        ? user?.profile_completion?.profile_pic
                        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                    }
                    alt=""
                  />
                  <div class="px-6 text-center">
                    <p class="text-lg font-semibold text-[#0F3D79] mb-1">
                      {user?.name}
                    </p>
                    <p class="text-md font-semibold text-gray-500 mb-1">
                      {user?.profile_completion?.category === ""
                        ? "": user?.profile_completion?.category === 'Student'? "Student"
                        : user?.profile_completion?.designation
                            ?.charAt(0)
                            .toUpperCase() +
                            user?.profile_completion?.designation?.slice(1)}
                    </p>
                    <p  class="text-sm font-semibold text-gray-500">
                      {user?.unique_id}
                    </p>
                  </div>
                </div>
              </div>
    </div>
  )
}

export default ProfileLogoInd