import React from 'react'
import PublishDisplays from './PublishDisplays'

function index() {
  return (
    <div>
        <PublishDisplays/>
    </div>
  )
}

export default index;