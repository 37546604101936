import React, { useEffect,  useState } from "react";
import "./Explore.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import ProfileLogoInd from "../UniversalComp/ProfileLogoInd";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ExploreIcon from "@mui/icons-material/Explore";
import { individualUserSelector } from "../../api/individualUser";
import { NavLink } from "react-router-dom";
import Image1 from "../../images/comingsoon.png";

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Import a PDF icon
import WorkIcon from "@mui/icons-material/Work";
import image2 from "../../images/logo.png";
import { Link,} from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";

import FilterIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search'; // Import the search icon
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import image3 from "../../images/bharcimg.png";



const useStyles = makeStyles((theme) => ({
  boxContainer: {
    marginBottom: "20px",
    height: "100px",
    width: "100%",
    overflowWrap: "break-word", // Use overflowWrap to enable word wrapping
    wordWrap: "break-word", // For compatibility with older browsers
  },
}));

function Competitions() {
  const { individualtoken, user, connected, request_received } = useSelector(
    individualUserSelector
  );

  const [type, setType] = useState("conference");
  const dispatch = useDispatch();
  const nav = useNavigate();
  const classes = useStyles();
  const { pathname } = useLocation();

  const reloadButton = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };



  return (
    <div>
      <section class="py-8" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap -mx-3">
            <div class="w-full lg:w-[22%] px-3 mb-12 lg:mb-0">
              <ProfileLogoInd user={user} />
              <div class="py-5 bg-gray-100 rounded-xl overflow-hidden">
              <NavLink to="/explore">
                  <div
                    class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"

                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Conferences
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/Competitions">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"
                      style={{
                        backgroundColor: pathname.includes("Competitions")
                          ? "#CEDDF6"
                          : "#F3F4F6",
                      }}
                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Competitions
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="/OtherEvents">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer"

                  >
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      OtherEvents
                      </h4>
                    </div>
                  </div>
                </NavLink>
                <NavLink to="#">
                  <div class="flex group py-3 px-2 sm:px-8 items-center hover:bg-blue-500 hover:bg-opacity-20 border-l-2 border-transparent hover:border-blue-500 transition duration-200 cursor-pointer">
                    <div class="flex-shrink-0 flex self-start items-center justify-center w-10 h-10 mr-4 bg-[#0F3D79] text-blue-50 rounded-full">
                      <ExploreIcon />
                    </div>
                    <div>
                      <h4 class="text-lg font-semibold text-[#0F3D79] mb-1">
                      Ads
                      </h4>
                    </div>
                  </div>
                </NavLink>
              </div>
            </div>



            <div className="w-full lg:w-[78%] px-3">
      <div className="h-full py-4 px-3 sm:px-10 bg-gray-100 rounded-xl">
        <div className="md:max-w-6xl mx-auto">
          {/* Header */}
          <div className="m-4">
          <h2 className="text-lg font-semibold text-[#0F3D79]">
          Explore
          <span className="text-sm"> - Competitions</span>
        </h2>

          </div>

        <div className="m-4 flex flex-col lg:flex-row justify-between items-center">
        {/* Left-aligned search bar */}
        <div className="flex-grow w-full lg:w-auto mb-4 lg:mb-0">
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon className="text-gray-500" />
            </span>
            <input
              type="text"
              placeholder="Search company name"
              className="py-2 pl-10 pr-4 w-full lg:w-1/2 rounded-xl border border-gray-300"
            />
          </div>
        </div>
        {/* Right-aligned filter and create buttons */}
        <div className="flex flex-col sm:flex-row items-center w-full lg:w-auto">
          <button
            className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto mb-4 sm:mb-0 sm:mr-4 lg:mr-2 lg:ml-4"
            type="button"
          >
            <FilterIcon /> Filter
          </button>
      
            <button
              className="py-2 px-4 text-[#0F3D79] font-semibold border rounded-xl bg-white w-full sm:w-auto"
              type="button"
            
            >
              <CalendarTodayIcon /> Date
            </button>
     
        </div>
      </div>

          <h2 className="text-sm font-semibold text-[#0F3D79] mb-5 ml-3">
            Upcoming Competitions
          </h2>

          {/* Topic Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
     

            {[...Array(6)].map((_, index) => (
               <NavLink to="/TopicView">
              <div key={index} className="bg-white p-6 rounded-xl shadow-md">
                 <div className="flex items-center mb-4">
                  <img
                    src={image2}  // Replace this with your image source
                    alt="Profile"
                    className="w-10 h-10 bg-gray-200 rounded-full mr-4 object-fit"
                  />
                  <div>
                    <h3 className=" text-[#0F3D79] text-md font-semibold">Google</h3>
                    <p className="text-sm text-gray-500">National Level Organization</p>
                  </div>
                </div>
                <h4 className="text-[#0F3D79] font-semibold mb-2">Event Name Goes Here</h4>
                <p className="text-gray-600 text-sm font-semibold "> 02/02/2022</p>
                
                <div className="flex justify-center items-center h-50 bg-gray-100 rounded-lg overflow-hidden">
                <img
                  src={image3}
                  alt="Description of the image"
                  className="w-full h-full object-fit"
                />
              </div>


              </div>
              </NavLink>
            ))}
          </div>
       
       
        </div>
      </div>
    </div>   





          </div>
        </div>
      </section>
    </div>
  );
}

export default Competitions;
